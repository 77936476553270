import React, { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Avatar, Box, CloseButton, Code, Text } from '@chakra-ui/react';

import { User } from '../../types';
import { InviteAppUser } from '../../components/app-users/InviteAppUser';
import { SoftCard } from '../../components/generic/SoftCard';
import { useAppUserActions } from '../../hooks/app-user-actions';
import { useAppsContext, useAuthContext } from '../../providers';
import { PaginatedTable } from '../../components/generic/PaginatedTable';
import { ConfirmButton } from '../../components/generic/ConfirmButton';
import { usePrivileges } from '../../hooks/roles';
import { useApiActions } from '../../hooks/api-actions';
import { RolePicker } from '../../components/app-users/RolePicker';

export const AppUsersPage: React.FC = () => {
  const { selectedApp: app } = useAppsContext();
  const [inviteLink, setInviteLink] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ users: User[]; total: number }>({ users: [], total: 0 });
  const { createAppInvitation, deleteAppUser } = useAppUserActions();
  const privileges = usePrivileges();
  const { executeApiAction } = useApiActions();
  const { user } = useAuthContext();

  const pageSize = 10;

  const getAppUsers = async ({ appId, page, size }: { appId: string; page: number; size: number }) => {
    setLoading(true);
    await executeApiAction<{ users: User[]; total: number }>({
      action: async ({ client }) => await client.get(`apps/${appId}/users`).json<{ users: User[]; total: number }>(),
      errorMessage: 'Failed to load users',
      onSuccess: (data: { users: User[]; total: number }) => {
        setData(data);
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    const getUsers = async () => {
      if (app) {
        await getAppUsers({ appId: app.id, page: 0, size: pageSize });
      }
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  if (!app) return null;

  const onInvite = async () => {
    const appInvitation = await createAppInvitation({ appId: app.id });
    if (appInvitation) {
      setInviteLink(`${window.location.protocol}//${window.location.host}/invitations/${appInvitation.id}`);
    }
  };

  return (
    <>
      {inviteLink && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          <Box w="full">
            <Box>
              <AlertTitle>Success!</AlertTitle>
              <AlertDescription>
                Share the following one time link with the user you want to invite. The link is valid for one hour.
              </AlertDescription>
            </Box>
            <Box>
              <Code>
                <Text wordBreak="break-all">{inviteLink}</Text>
              </Code>
            </Box>
          </Box>

          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => {
              setInviteLink('');
            }}
          />
        </Alert>
      )}
      <SoftCard>
        <PaginatedTable<User>
          columns={[
            {
              id: 'avatar',
              render: ({ row }) => <Avatar name={`${row.firstName} ${row.lastName}`} src={row.picture} size="sm" />,
            },
            { id: 'name', title: 'Name', render: ({ row }) => `${row.firstName} ${row.lastName}` },
            { id: 'email', title: 'Email', render: ({ row }) => row.email },
            { id: 'role', title: 'Role', render: ({ row }) => user && <RolePicker app={app} user={row} /> },
            {
              id: 'setRole',
              render: ({ row }) =>
                privileges.APP_USERS.DELETE && row.role !== 'OWNER' ? (
                  <ConfirmButton
                    minW={100}
                    onClick={() => deleteAppUser(app.id, row.id)}
                    title={'Delete'}
                    body={'Are you sure you want to delete this user?'}
                    colorScheme="red"
                  />
                ) : null,
            },
          ]}
          data={data.users}
          totalRecords={data.total}
          isLoading={loading}
          pageSize={10}
          cta={[<InviteAppUser onSubmit={onInvite} />]}
          onChangePage={async ({ page, size }) => {
            await getAppUsers({ appId: app.id, page, size });
          }}
          onRefresh={async ({ page, size }) => {
            await getAppUsers({ appId: app.id, page, size });
          }}
          emptyMessage="No users to display"
        />
      </SoftCard>
    </>
  );
};
