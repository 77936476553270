import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Footer, MainContent, MainPanel } from '../components/layout';

interface DefaultLayoutProps {
  children: React.ReactNode;
  background?: string | null;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, background }) => {
  return (
    <Flex flexDir="column" minH="100vh" background={background ?? 'inherit'}>
      <MainPanel>
        <MainContent>{children}</MainContent>
        <Footer />
      </MainPanel>
    </Flex>
  );
};
