import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { SelectInputStepTypeConfig, StepConfig } from '@packages/clevis/src';
import { UseFormRegister, useFormContext } from 'react-hook-form';
import { RunAppInput } from '../AppRunner';

export const SelectInput = ({
  step,
  register,
  disabled = false,
  ...props
}: {
  step: StepConfig<SelectInputStepTypeConfig>;
  register: UseFormRegister<RunAppInput>;
  disabled?: boolean;
} & SelectProps) => {
  const {
    formState: { errors },
  } = useFormContext<RunAppInput>();

  const { title, options, helperText } = step.config as SelectInputStepTypeConfig;

  return (
    <FormControl isRequired isInvalid={!!errors[step.id]}>
      <FormLabel>{title}</FormLabel>
      <Select
        disabled={disabled}
        key={step.id}
        {...props}
        {...register(step.id, { required: 'This field is required' })}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </Select>
      {errors && errors[step.id]?.message && <FormErrorMessage>{errors[step.id]?.message}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
