import { BoxProps, CloseButton, Flex, Text } from '@chakra-ui/react';
import { useAppsContext } from '../../providers';
import {
  MdBuild,
  MdColorLens,
  MdList,
  MdLock,
  MdOutlineApi,
  MdPayments,
  MdSchedule,
  MdShare,
  MdFileUpload,
} from 'react-icons/md';
import { NavItem, NavItemProps } from './NavItem';
import { Logo } from './Logo';
import { UsageCard } from '../UsageCard';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const { selectedApp } = useAppsContext();

  const Categories: Array<{ name: string; items: NavItemProps[] }> = [
    {
      name: 'Build',
      items: [
        {
          name: 'Appearance',
          to: `/apps/${selectedApp?.id}/appearance`,
          icon: MdColorLens,
        },
        {
          name: 'Editor',
          to: `/apps/${selectedApp?.id}/editor`,
          icon: MdBuild,
        },
        {
          name: 'Files',
          to: `/apps/${selectedApp?.id}/files`,
          icon: MdFileUpload,
        },
        {
          name: 'Secrets',
          to: `/apps/${selectedApp?.id}/secrets`,
          icon: MdLock,
        },
      ],
    },
    {
      name: 'Run',
      items: [
        {
          name: 'API',
          to: `/apps/${selectedApp?.id}/api`,
          icon: MdOutlineApi,
        },
        {
          name: 'Share',
          to: `/apps/${selectedApp?.id}/share`,
          icon: MdShare,
        },
        {
          name: 'Schedule',
          to: `/apps/${selectedApp?.id}/schedule`,
          icon: MdSchedule,
        },
      ],
    },
    {
      name: 'Monitor',
      items: [
        {
          name: 'Runs',
          to: `/apps/${selectedApp?.id}/runs`,
          icon: MdList,
        },
      ],
    },
    {
      name: 'Monetize',
      items: [
        {
          name: 'Payments',
          to: `/apps/${selectedApp?.id}/payments`,
          icon: MdPayments,
        },
      ],
    },
  ];

  return (
    <Flex
      transition="2s ease"
      w={{ base: 'full', md: 60 }}
      flexDirection="column"
      height="full"
      maxHeight="100vh"
      overflow="scroll"
      px={2}
      gap={4}
      {...rest}
    >
      <Flex alignItems="center" justifyContent={{ base: 'space-between' }} p={4}>
        <Logo />
        <CloseButton onClick={onClose} display={{ base: 'flex', md: 'none' }} />
      </Flex>
      <Flex flexDirection="column" gap={4} flexGrow={1} py={4}>
        {Categories.map(({ name, items }) => (
          <Flex flexDirection="column" key={name}>
            <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" px={4} mb={1}>
              {name}
            </Text>
            <Flex flexDir="column" gap={1}>
              {items.map((item) => (
                <NavItem to={item.to} key={item.to} icon={item.icon} name={item.name} />
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex py={4}>
        <UsageCard />
      </Flex>
    </Flex>
  );
};
