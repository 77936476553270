import { Flex, Heading, Icon, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type PageHeadingProps = {
  title?: string;
  size?: string;
  icon?: IconType;
  cta?: React.ReactNode;
};

const IconCircle = ({ icon }: { icon: any }) => {
  const background = useColorModeValue('gray.200', 'gray.600');
  return (
    <Flex borderRadius="50%" w={8} h={8} mr="4" background={background} alignItems="center" justifyContent="center">
      <Icon as={icon} boxSize={5} />
    </Flex>
  );
};

export const PageHeading = ({ title, size = 'md', icon, cta }: PageHeadingProps) => {
  return (
    <Flex justifyContent="space-between" w="full" >
      <Flex alignItems="center">
        {icon && <IconCircle icon={icon} />}
        <Heading size={size}>{title}</Heading>
      </Flex>
      <Flex gap="4">{cta}</Flex>
    </Flex>
  );
};
