import React, { useEffect, useState } from 'react';
import { Alert, AlertIcon, Box, Button, Code, Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import { ApiKey } from '../../types/api-key';
import { useAppsContext } from '../../providers';
import { useApiActions } from '../../hooks/api-actions';
import format from 'date-fns/format';
import { formatDistanceToNow } from 'date-fns';
import { MdAdd, MdDelete } from 'react-icons/md';

type ApiKeyResponse = {
  apiKey: ApiKey;
};

export const ApiKeyEditor: React.FC = () => {
  const { executeApiAction } = useApiActions();
  const { selectedApp } = useAppsContext();
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);
  const [alert, setAlert] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentApiKey = async () => {
      await executeApiAction({
        action: async ({ client }) =>
          (
            await client.get(`apps/${selectedApp?.id}/api-key`).json<ApiKeyResponse>()
          ).apiKey,
        onSuccess: (data: ApiKey) => {
          setApiKey(data);
        },
      });
      setIsLoading(false);
    };

    fetchCurrentApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (apiKey) {
    return (
      <Flex flexDirection="column" gap={4}>
        {alert && (
          <Alert status="warning" variant="subtle">
            <AlertIcon />
            {alert}
          </Alert>
        )}

        <Flex gap={1} flexDirection="column">
          <Flex gap={2}>
            <Text fontWeight="bold">Key: </Text>
            <Text>
              <Code>{apiKey.key}</Code>
            </Text>
          </Flex>
          <Flex gap={2}>
            <Text fontWeight="bold">Created: </Text>
            <Text>{format(new Date(apiKey.createdAt), 'yyyy-MM-dd')}</Text>
          </Flex>
          <Flex gap={2}>
            <Text fontWeight="bold">Last used: </Text>
            <Text>
              {apiKey.lastUsed ? formatDistanceToNow(new Date(apiKey.lastUsed), { addSuffix: true }) : 'Never'}
            </Text>
          </Flex>
        </Flex>

        <Flex>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await executeApiAction({
                action: async ({ client }) => await client.delete(`apps/${selectedApp?.id}/api-key`),
                onError: () => {
                  setIsLoading(false);
                },
                onSuccess: () => {
                  setIsLoading(false);
                  setApiKey(null);
                },
              });
            }}
            colorScheme="red"
            leftIcon={<MdDelete />}
          >
            Revoke
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Box>
      <IconButton
      aria-label="Create API key"
        onClick={async () => {
          setIsLoading(true);
          await executeApiAction({
            action: async ({ client }) =>
              (
                await client.post(`apps/${selectedApp?.id}/api-key`).json<ApiKeyResponse>()
              ).apiKey,
            onSuccess: (data: ApiKey) => {
              setApiKey(data);
              setAlert('Keep this key somewhere safe. For security reasons, you will not be able to view it again.');
              setIsLoading(false);
            },
            onError: () => {
              setIsLoading(false);
            },
          });
        }}
        icon={<MdAdd />}
      >
        Create new
      </IconButton>
    </Box>
  );
};
