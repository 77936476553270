import { StepCategory, StepType, getStepCategory } from '@packages/clevis';
import { IconType } from 'react-icons';
import {
  MdAssistant,
  MdEmail,
  MdInput,
  MdMap,
  MdOutlineFormatAlignCenter,
  MdOutlineImage,
  MdOutlineMenu,
  MdOutlineMultipleStop,
} from 'react-icons/md';
import { TextInputStep } from './TextInputStep';
import { PromptChatGPTStep } from './PromptChatGPTStep';
import { DisplayOutputStep } from './DisplayOutputStep';
import { HTTPRequestStep } from './HTTPRequestStep';
import { SelectInputStep } from './SelectInputStep';
import { DallEStep } from './DallEStep';
import React from 'react';
import { GeolocationInputStep } from './GeolocationStep';
import { SendEmailStep } from './SendEmailStep';

export type StepTypeMetadata = {
  type: StepType;
  icon: IconType;
  title: string;
  description: string;
  details: string;
  component: React.ReactElement;
  category: StepCategory;
  getOutputs: (stepId: string) => Array<{ key: string; variable: string; description: string }>;
};

export const StepTypeMetadataMap: Record<StepType, StepTypeMetadata> = {
  TEXT_INPUT: {
    type: StepType.TEXT_INPUT,
    icon: MdInput,
    title: 'Text Input',
    description: 'Collect text input from the user',
    details:
      'Present an input field for the user to provide text in. The input can then be used in other steps of your app.',
    component: <TextInputStep />,
    category: getStepCategory(StepType.TEXT_INPUT),
    getOutputs: (stepId) => [
      { key: 'output', variable: `steps.${stepId}.output`, description: 'The value that the user entered' },
    ],
  },
  CHATGPT: {
    type: StepType.CHATGPT,
    icon: MdAssistant,
    title: 'Prompt ChatGPT',
    description: 'Prompt ChatGPT with a custom prompt',
    details:
      'Create a chat completion with ChatGPT. This step works by combining a set of prompts to use by ChatGPT. If your are unsure about what role to use, select "User".',
    component: <PromptChatGPTStep />,
    category: getStepCategory(StepType.CHATGPT),
    getOutputs: (stepId) => [
      { key: 'output', variable: `steps.${stepId}.output`, description: 'The response from ChatGPT as text' },
    ],
  },
  DISPLAY_OUTPUT: {
    type: StepType.DISPLAY_OUTPUT,
    icon: MdOutlineFormatAlignCenter,
    title: 'Display Output',
    details:
      'This step can be used to format your output and display it to the user. For example, by adding an image from a DALL-E step and a text from a ChatGPT step.',
    description: 'Display output to the user',
    component: <DisplayOutputStep />,
    category: getStepCategory(StepType.DISPLAY_OUTPUT),
    getOutputs: (stepId) => [
      { key: 'output', variable: `steps.${stepId}.output`, description: 'The output from the step as text' },
    ],
  },
  HTTP_REQUEST: {
    type: StepType.HTTP_REQUEST,
    icon: MdOutlineMultipleStop,
    title: 'HTTP Request',
    description: 'Make an HTTP request to any URL',
    details:
      'Make an HTTP request to fetch data from or send data to a URL. For example, fetch data from a website and use it in a later step,or POST the result data from your app to an API.',
    component: <HTTPRequestStep />,
    category: getStepCategory(StepType.HTTP_REQUEST),
    getOutputs: (stepId) => [
      {
        key: 'output',
        variable: `steps.${stepId}.output`,
        description: 'The body from your request call as JSON',
      },
    ],
  },
  SELECT_INPUT: {
    type: StepType.SELECT_INPUT,
    icon: MdOutlineMenu,
    title: 'Select Input',
    description: 'Collect user input from a select menu',
    details: 'Get a selection from a user by displaying a dropdown with your options.',
    component: <SelectInputStep />,
    category: getStepCategory(StepType.SELECT_INPUT),
    getOutputs: (stepId) => [
      {
        key: 'output',
        variable: `steps.${stepId}.output`,
        description: 'The value from the selection',
      },
    ],
  },
  DALL_E: {
    type: StepType.DALL_E,
    icon: MdOutlineImage,
    title: 'Generate Image (DALL-E)',
    description: 'Generate an image using DALL-E',
    details:
      'Generate an image using DALL-E. Use references to previous steps to add dynamic variables in your DALL-E prompt.',
    component: <DallEStep />,
    category: getStepCategory(StepType.DALL_E),
    getOutputs: (stepId) => [
      {
        key: 'output.images[0].url',
        variable: `steps.${stepId}.output.images[0].url`,
        description: 'The URL to the first image',
      },
      {
        key: 'output.images[1].url',
        variable: `steps.${stepId}.output.images[1].url`,
        description: 'The URL to the second image',
      },
      {
        key: 'output.images[2].url',
        variable: `steps.${stepId}.output.images[2].url`,
        description: 'The URL to the third image',
      },
      {
        key: 'output.images[3].url',
        variable: `steps.${stepId}.output.images[3].url`,
        description: 'The URL to the fourth image',
      },
      {
        key: 'output.images[4].url',
        variable: `steps.${stepId}.output.images[4].url`,
        description: 'The URL to the fifth image',
      },
    ],
  },
  GEOLOCATION_INPUT: {
    type: StepType.GEOLOCATION_INPUT,
    icon: MdMap,
    title: 'Geolocation',
    description: 'Get the users current location',
    details:
      'Collects the users current location from their browser after asking for consent. The latitude and longitude of the user will be available by referencing this step in subsequent steps.',
    component: <GeolocationInputStep />,
    category: getStepCategory(StepType.GEOLOCATION_INPUT),
    getOutputs: (stepId) => [
      {
        key: 'output.latitude',
        variable: `steps.${stepId}.output.latitude`,
        description: 'The users latitude as a coordinate',
      },
      {
        key: 'output.longitude',
        variable: `steps.${stepId}.output.longitude`,
        description: 'The users longitude as a coordinate',
      },
    ],
  },
  SEND_EMAIL: {
    type: StepType.SEND_EMAIL,
    icon: MdEmail,
    title: 'Send Email',
    description: 'Send an e-mail',
    details:
      'Send an email to an address of your choosing. For example, gather input from your users with input steps, process the input and send the result to your own email address.',
    component: <SendEmailStep />,
    category: getStepCategory(StepType.SEND_EMAIL),
    getOutputs: () => [],
  },
};

export const stepTypeMetadataList = [...Object.values(StepTypeMetadataMap)];
