import {
  Button,
  Flex,
  IconButton,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import _ from 'lodash';

interface ColumnProps<T> {
  id: string;
  title?: string;
  render: ({ row }: { row: T }) => any;
}

interface PaginatedTableProps<T> {
  data: Array<T>;
  columns: Array<ColumnProps<T>>;
  totalRecords: number;
  isLoading: boolean;
  pageSize?: number;
  emptyMessage?: string;
  cta?: Array<React.ReactNode>;
  onChangePage: ({ page, size }: { page: number; size: number }) => void;
  onRefresh?: ({ page, size }: { page: number; size: number }) => void;
}

export const PaginatedTable = <T extends Record<string, unknown>>({
  data,
  columns,
  totalRecords,
  pageSize = 10,
  isLoading,
  emptyMessage,
  cta,
  onChangePage,
  onRefresh,
}: PaginatedTableProps<T>) => {
  const [pageIndex, setPageIndex] = useState(0);
  const mounted = useRef(false);
  const tableHeadColor = useColorModeValue('gray.100', 'gray.800');

  const startCount = pageIndex * pageSize + 1;
  const endCount = startCount + data.length - 1;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    onChangePage({ page: pageIndex, size: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  return (
    <TableContainer>
      <Flex direction="row" justifyContent="flex-end" mb={2} gap={2}>
        {cta}
        {onRefresh && (
          <IconButton
            aria-label="refresh"
            icon={<MdRefresh />}
            onClick={() => onRefresh({ page: pageIndex, size: pageSize })}
          />
        )}
      </Flex>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            {columns.map(({ id, title }) => (
              <Th key={id} bg={tableHeadColor}>
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {isLoading &&
            _.times(5, (num: number) => (
              <Tr key={num}>
                {columns.map((_, index) => (
                  <Td key={index}>
                    <Skeleton height={5} />
                  </Td>
                ))}
              </Tr>
            ))}
          {!isLoading &&
            data &&
            data.map((row: T, index) => (
              <Tr key={index}>
                {columns.map((column) => (
                  <Td key={column.id}>{column.render({ row })}</Td>
                ))}
              </Tr>
            ))}
          {emptyMessage && !isLoading && data && data.length === 0 && (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                <Text>{emptyMessage}</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center" mt={4}>
        <Flex>{totalRecords > 0 && <Text>{`Showing ${startCount} to ${endCount} of ${totalRecords} `}</Text>}</Flex>
        <Flex gap={4}>
          <Button isDisabled={pageIndex === 0} onClick={() => setPageIndex(pageIndex - 1)}>
            Previous
          </Button>
          <Button isDisabled={endCount >= totalRecords} onClick={() => setPageIndex(pageIndex + 1)}>
            Next
          </Button>
        </Flex>
      </Flex>
    </TableContainer>
  );
};
