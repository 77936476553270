import { ChatGPTRole, ChatGptModel, StepType, TextInputType } from '@packages/clevis';
import { Template } from '../types';

export const recipeCreator: Template = {
  title: 'Recipe Creator',
  description:
    'Do you suffer from a lack of inspiration in the kitchen? Let the AI generate some tasty recipes based on your dietary preferences',
  appConfig: {
    steps: [
      {
        id: 'diet',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter your diet',
          inputType: TextInputType.INPUT,
          helperText: 'For example "Keto" or "Vegan"',
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.USER,
              content: 'Give me a {{steps.diet.output}} friendly recipe',
            },
          ],
        },
      },
      {
        id: 'display',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '{{steps.gpt.output}}',
        },
      },
    ],
  },
};
