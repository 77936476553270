import React from 'react';
import { useAppsContext } from '../../providers';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdEdit, MdLock } from 'react-icons/md';
import { ConfirmButton } from '../generic/ConfirmButton';
import { useForm } from 'react-hook-form';
import { useAppActions } from '../../hooks';

type FormProps = {
  key: string;
  value: string;
};

const EditSecret: React.FC<{ secret?: string }> = ({ secret }) => {
  const { selectedApp } = useAppsContext();
  const { updateSecret, createSecret } = useAppActions();
  const { register, getValues, reset } = useForm<FormProps>(secret ? { values: { key: secret, value: '' } } : {});
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (!selectedApp) return null;
  return (
    <>
      <IconButton aria-label="edit secret" onClick={onOpen} icon={secret ? <MdEdit /> : <MdAdd />} />
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex justifyContent="space-between">
              {secret ? 'Edit' : 'Create'} secret {secret}
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <FormControl>
              <Box hidden={!!secret}>
                <FormLabel htmlFor="key">Key</FormLabel>
                <Input
                  {...register('key', { required: 'Enter a valid key' })}
                  required
                  defaultValue={secret}
                  fontSize="sm"
                  placeholder="MY_SECRET"
                  mb={4}
                />
              </Box>
              <FormLabel>Value</FormLabel>
              <Textarea
                {...register('value', { required: 'Enter a valid value' })}
                required
                placeholder="Your secret value"
              ></Textarea>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Flex flexGrow={1} alignItems="flex-start">
              <Button
                onClick={() => {
                  const data = { appId: selectedApp.id, ...getValues() };
                  if (secret) {
                    updateSecret(data);
                  } else {
                    createSecret(data);
                  }
                  onClose();
                  reset();
                }}
              >
                Save
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const SecretsEditor: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { deleteSecret } = useAppActions();

  if (!selectedApp) return null;

  return (
    <>
      {selectedApp?.secrets &&
        selectedApp.secrets.map((secret, index) => (
          <React.Fragment key={index}>
            <Flex mb="4" gap="2">
              <InputGroup>
                <InputLeftElement>
                  <Icon as={MdLock} opacity={0.8} />
                </InputLeftElement>
                <Input fontSize="sm" placeholder={secret.key} style={{ opacity: 1 }} disabled />
              </InputGroup>
              <EditSecret secret={secret.key} />
              <ConfirmButton
                iconSpacing={0}
                icon={<MdDelete />}
                onClick={() => deleteSecret({ appId: selectedApp.id, key: secret.key })}
                body={`Are you sure you want to delete ${secret.key}?`}
                colorScheme="red"
              />
            </Flex>
          </React.Fragment>
        ))}
      <Box>
        <EditSecret />
      </Box>
    </>
  );
};
