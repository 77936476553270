import { ChatGPTRole, ChatGptModel, StepType } from '@packages/clevis';
import { Template } from '../types';

export const aiBartender: Template = {
  title: 'Cocktail Creator',
  description:
    'Need some inspiration for a cocktail to serve at the next family gathering or birthday party? The AI Bartender got you covered. Just enter the occasion you want a drink for and the AI will generate a new recipe!',
  appConfig: {
    steps: [
      {
        id: 'input',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter the occasion you need a drink for',
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.SYSTEM,
              content: 'You are a helpful assistant that will generate a cocktail recipe for the occasion I provide',
            },
            {
              role: ChatGPTRole.USER,
              content: '{{ steps.input.output }}',
            },
          ],
        },
      },
      {
        id: 'output',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: 'Here is your new cocktail recipe!\n\n{{ steps.gpt.output }}',
        },
      },
    ],
  },
};
