import React, { useEffect } from 'react';
import {
  Button,
  useDisclosure,
  Icon,
  Alert,
  AlertIcon,
  Flex,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  Heading,
  AlertDescription,
  AlertTitle,
  Box,
  CloseButton,
  DrawerCloseButton,
  DrawerFooter,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react';
import { StepCategory, StepConfig, StepType, StepTypeConfig } from '@packages/clevis';
import { FormProvider, useForm } from 'react-hook-form';
import { MdAdd, MdArrowBack } from 'react-icons/md';
import { SoftCard } from '../generic/SoftCard';
import { StepTypeMetadata, StepTypeMetadataMap, stepTypeMetadataList } from './types';
import { useAppsContext } from '../../providers';
import { App } from '../../types';

const getId = (type: StepType, app: App): string => {
  if (!app.appConfig) return 'step-id';
  const count = app.appConfig?.steps.reduce((total, current) => (current.type === type ? total + 1 : total), 0);

  return count > 0 ? `${type.toLowerCase()}_${count + 1}` : type.toLocaleLowerCase();
};

const StepTypeCard = ({ step, onClick }: { step: StepTypeMetadata; onClick: () => void }) => (
  <Box _hover={{ cursor: 'pointer' }}>
    <SoftCard onClick={onClick}>
      <Flex alignItems="center" flexDirection="column">
        <Icon flex={1} boxSize={6} as={step.icon} />
        <Text as="b">{step.title}</Text>
        <Text>{step.description}</Text>
      </Flex>
    </SoftCard>
  </Box>
);

export const StepEditor = ({
  save,
  cancel,
  errorMessage,
  onCloseError,
  stepData,
}: {
  save: (step: StepConfig<StepTypeConfig>, index?: number) => Promise<void>;
  cancel: () => void;
  errorMessage: string | null;
  onCloseError: () => void;
  stepData?: { step: StepConfig<StepTypeConfig>; index: number };
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedApp } = useAppsContext();

  const methods = useForm<StepConfig<StepTypeConfig>>({
    values: stepData?.step,
  });

  const { register, handleSubmit, reset, setValue, getValues, watch } = methods;

  const type = watch('type');

  const formatId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value.toLowerCase();
    const replacements: Record<string, string> = {
      ' ': '_',
    };
    const regex = /[^a-zA-Z0-9-_]/g;
    const formattedId = id.replace(regex, (match) => replacements[match] || '');
    setValue('id', formattedId);
  };

  useEffect(() => {
    if (stepData) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData]);

  const _cancel = () => {
    cancel();
    onClose();
  };

  const onSubmit = async (data: StepConfig<StepTypeConfig>) => {
    await save(data, stepData?.index);
    onClose();
  };

  if (!selectedApp) return null;

  const setStepType = (step: StepTypeMetadata) => {
    setValue('type', step.type);
    setValue('config', {});

    const currentId = getValues('id');
    if (!currentId) {
      setValue('id', getId(step.type, selectedApp));
    }
  };

  return (
    <>
      <SoftCard
        w="100%"
        onClick={() => {
          reset({ config: {} });
          onOpen();
        }}
        cursor="pointer"
        alignItems="center"
      >
        <Icon boxSize="36px" as={MdAdd} />
        <Text mt={2}>Add step</Text>
      </SoftCard>
      <Drawer isOpen={isOpen} onClose={_cancel} size="md">
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerContent overflow="scroll">
              <DrawerCloseButton />

              <DrawerHeader fontSize="lg" fontWeight="bold">
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex justifyContent="center" alignItems="center">
                    {type && !stepData && (
                      <Icon
                        _hover={{ cursor: 'pointer' }}
                        as={MdArrowBack}
                        mr={2}
                        onClick={() => {
                          reset();
                        }}
                      />
                    )}

                    <Text>{type ? 'Edit step' : 'Add step'}</Text>
                  </Flex>
                </Flex>
              </DrawerHeader>
              <DrawerBody pb={32}>
                <Flex flexDir="column" gap={4}>
                  {errorMessage && (
                    <Alert status="warning">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Invalid step</AlertTitle>
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Box>
                      <CloseButton
                        alignSelf="flex-start"
                        position="absolute"
                        right={1}
                        top={1}
                        onClick={onCloseError}
                      />
                    </Alert>
                  )}
                  {type ? (
                    <Flex flexDirection="column" gap={8}>
                      <Flex flexDirection="column" gap={2}>
                        <Text as="b">{StepTypeMetadataMap[type].title}</Text>
                        <Text fontSize="sm">{StepTypeMetadataMap[type].details}</Text>
                      </Flex>
                      <Flex flexDirection="column" gap={2}>
                        <Text as="b">Configure</Text>
                        <FormControl isRequired>
                          <FormLabel htmlFor="id" fontSize="sm">
                            ID
                          </FormLabel>
                          <Input fontSize="sm" {...register('id', { required: 'Provide an ID', onChange: formatId })} />
                          <FormHelperText>Can be used to reference your step</FormHelperText>
                        </FormControl>
                        {StepTypeMetadataMap[type].component}
                      </Flex>
                    </Flex>
                  ) : (
                    <>
                      <Heading as="h2" size="sm">
                        Inputs
                      </Heading>
                      {stepTypeMetadataList
                        .filter((step) => step.category === StepCategory.INPUT)
                        .map((step) => {
                          return <StepTypeCard key={step.type} step={step} onClick={() => setStepType(step)} />;
                        })}
                      <Heading as="h2" size="sm">
                        Processing
                      </Heading>
                      {stepTypeMetadataList
                        .filter((step) => step.category === StepCategory.PROCESSING)
                        .map((step) => {
                          return <StepTypeCard key={step.type} step={step} onClick={() => setStepType(step)} />;
                        })}
                      <Heading as="h2" size="sm">
                        Output
                      </Heading>
                      {stepTypeMetadataList
                        .filter((step) => step.category === StepCategory.OUTPUT)
                        .map((step) => {
                          return <StepTypeCard key={step.type} step={step} onClick={() => setStepType(step)} />;
                        })}
                    </>
                  )}
                </Flex>
              </DrawerBody>
              <DrawerFooter>
                <Flex flexGrow={1} alignItems="flex-start">
                  <Button type="submit">{stepData ? 'Update' : 'Add'}</Button>
                </Flex>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </>
  );
};
