import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { MdPayments } from 'react-icons/md';

import { SoftCard } from '../../components/generic/SoftCard';
import { useSubscriptionActions } from '../../hooks';
import { useAuthContext } from '../../providers';

export const PaymentsSettingsPage: React.FC = () => {
  const { user } = useAuthContext();
  const { configureAccount } = useSubscriptionActions();

  return (
    <SoftCard>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h3" size="sm" mb={2}>
            Manage payments
          </Heading>
          <Text>Clevis uses Stripe to manage payments. Configure or update your Stripe account details.</Text>
        </Box>

        <Button rightIcon={<MdPayments />} onClick={configureAccount}>
          {user?.stripeAccountChargesEnabled ? 'Update' : 'Configure'}
        </Button>
      </Flex>
    </SoftCard>
  );
};
