import { ChatGPTRole, ChatGptModel, StepType, TextInputType } from '@packages/clevis';
import { Template } from '../types';

export const resumeSummarizer: Template = {
  title: 'Resume Summarizer',
  description: 'Summarize a candidates resume based on a job description.',
  appConfig: {
    steps: [
      {
        id: 'text_input',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Paste the candidates resume',
          inputType: TextInputType.TEXT_AREA,
        },
      },
      {
        id: 'text_input_2',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Paste the job description',
          inputType: TextInputType.TEXT_AREA,
        },
      },
      {
        id: 'chatgpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_4,
          messages: [
            {
              role: ChatGPTRole.USER,
              content:
                'You are a recruiter with the goal of finding suitable applicants for a job position.\n\nBelow is a job applicants resume and a job description. Write a pitch of the candidate that emphasizes how well the candidate matches the job description.\n\nResume: {{steps.text_input.output}}\n\nJob description: {{steps.text_input_2.output}}',
            },
          ],
        },
      },
      {
        id: 'display',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '{{steps.chatgpt.output}}',
        },
      },
    ],
  },
};
