import { useCallback, useState } from 'react';

import { createEditor, BaseEditor, Descendant, Transforms, Editor, Location, Text } from 'slate';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
  useSlateStatic,
  RenderPlaceholderProps,
} from 'slate-react';
import { Text as ChakraText, Flex, Tag, TagLeftIcon, Textarea, TextareaProps } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import { MdDelete } from 'react-icons/md';
import { VariablesMenu } from './VariablesMenu';
import { SecretsMenu } from './SecretsMenu';
import { ContextMenu } from './ContextMenu';

type TagElement = {
  type: 'tag';
  children: CustomText[];
};

type ParagraphElement = {
  type: 'paragraph';
  children: CustomText[];
};

type CustomElement = TagElement | ParagraphElement;

type CustomText = { text: string };

type VariableType = 'variables' | 'secrets' | 'contexts' | 'custom';

type TemplateAreaProps = TextareaProps & {
  name: string;
  control: any;
  variableTypes: VariableType[];
  singleLine?: boolean;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const CustomEditor = {
  addVariable: ({ value, editor }: { value: string; editor: Editor }) => {
    const tag: TagElement = {
      type: 'tag',
      children: [{ text: value || '' }],
    };

    ReactEditor.focus(editor);
    Transforms.insertNodes(editor, [tag]);
    Transforms.move(editor, { unit: 'offset', distance: 1 });
  },

  removeVariable: ({ editor, at }: { editor: Editor; at: Location }) => {
    Transforms.removeNodes(editor, { at });
  },
};

const TagElementComponent = (props: { element: TagElement; children: React.ReactNode; attributes: any }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, props.element);
  return (
    <span {...props.attributes}>
      <Tag mx={1} size="sm">
        <TagLeftIcon
          boxSize="12px"
          as={MdDelete}
          _hover={{ cursor: 'pointer' }}
          marginInlineEnd={1}
          onClick={() => CustomEditor.removeVariable({ editor, at: path })}
        />
        {props.children}
      </Tag>
    </span>
  );
};

const DefaultElementComponent = (props: RenderElementProps) => {
  return <p {...props.attributes}>{props.children}</p>;
};

const withTagPlugin = (editor: ReactEditor) => {
  const { isInline } = editor;

  editor.isInline = (element) => {
    if (['tag'].includes(element.type)) {
      return true;
    }
    return isInline(element);
  };

  return editor;
};

const withSingleLine = (editor: ReactEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export const TemplateArea = ({ name, control, variableTypes, singleLine = false, placeholder }: TemplateAreaProps) => {
  const [editor] = useState(() =>
    singleLine ? withSingleLine(withReact(withTagPlugin(createEditor()))) : withReact(withTagPlugin(createEditor()))
  );

  const serialize = (descendant: Descendant): string => {
    if (Text.isText(descendant)) return descendant.text;

    const children = descendant.children.map((n) => serialize(n)).join('');
    switch (descendant.type) {
      case 'tag':
        return `{{ ${children} }}`;
      case 'paragraph':
        return children + '\n';
      default:
        return children;
    }
  };

  const deserialize = (value: string): Descendant[] => {
    if (!value) return [{ type: 'paragraph', children: [{ text: '' }] }];

    const result = value.split('\n').map((item) => {
      const tagRegex = /({{.*?}})/;
      const parts = item.split(/({{.*?}})/);

      const result: Descendant[] = parts.map((part) => {
        const isTag = tagRegex.test(part);
        if (isTag) {
          const tagValue = part.slice(part.indexOf('{{') + 2, part.indexOf('}}'));
          return { type: 'tag', children: [{ text: tagValue }] };
        } else {
          return { text: part };
        }
      });

      return { type: 'paragraph', children: result };
    });

    return result as Descendant[];
  };

  const renderElement = useCallback(({ ...props }: RenderElementProps) => {
    switch (props.element.type) {
      case 'tag':
        return <TagElementComponent {...props} element={props.element as TagElement} />;
      default:
        return <DefaultElementComponent {...props} />;
    }
  }, []);

  const renderPlaceholder = useCallback(({ attributes, children }: RenderPlaceholderProps) => {
    return (
      <ChakraText as="span" {...attributes} py={2}>
        <span>{children}</span>
      </ChakraText>
    );
  }, []);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, formState }) => (
          <Slate
            editor={editor}
            initialValue={deserialize(value)}
            onChange={(nodes) => {
              const res = nodes.map((node) => serialize(node));
              const response = res.join('').replace(/\n+$/, '').trim();
              onChange(response);
            }}
          >
            <Textarea
              onBlur={onBlur}
              as={Editable}
              placeholder={placeholder}
              renderElement={renderElement}
              renderPlaceholder={renderPlaceholder}
              rows={20}
              maxHeight={singleLine ? undefined : 400}
              minHeight={singleLine ? undefined : 150}
              overflow="scroll"
            />
          </Slate>
        )}
      />
      <Flex my={2} gap={2}>
        {variableTypes.includes('variables') && (
          <VariablesMenu
            onSelect={({ key, value }) => {
              CustomEditor.addVariable({
                value: value,
                editor,
              });
            }}
          />
        )}

        {variableTypes.includes('secrets') && (
          <SecretsMenu
            onSelect={({ key, value }) => {
              CustomEditor.addVariable({
                value: value,
                editor,
              });
            }}
          />
        )}

        {variableTypes.includes('contexts') && (
          <ContextMenu
            onSelect={({ key, value }) => {
              CustomEditor.addVariable({
                value: value,
                editor,
              });
            }}
          />
        )}

        {variableTypes.includes('custom') && (
          <Tag
            variant="outline"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              CustomEditor.addVariable({
                value: '',
                editor,
              });
              ReactEditor.focus(editor);
            }}
          >
            <TagLeftIcon boxSize="12px" as={AddIcon} />
            Custom
          </Tag>
        )}
      </Flex>
    </>
  );
};
