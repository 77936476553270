import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { Plan, StripeSession } from '../types';
import { useApiActions } from './api-actions';

type CreateStripeBillingPortalSessionResponse = {
  stripeBillingPortalSession: StripeSession;
};

type CreateStripeSessionResponse = {
  stripeSession: StripeSession;
};

type StripePlansReponse = {
  plans: Plan[];
};

type StripeAccountLinkResponse = {
  url: string;
};

export const useSubscriptionActions = () => {
  const { executeApiAction } = useApiActions();

  const manageSubscriptions = async () =>
    await executeApiAction<CreateStripeBillingPortalSessionResponse>({
      action: ({ client }) =>
        client.post('stripe/billing-portal-sessions').json<CreateStripeBillingPortalSessionResponse>(),
      onSuccess: (response: CreateStripeBillingPortalSessionResponse) => {
        window.location.href = response.stripeBillingPortalSession.url;
      },
      errorMessage:
        'Failed to create Stripe session. Make sure you have configured your Stripe API keys for this environment.',
    });

  const activateSubscription = async (planId: string) =>
    await executeApiAction<CreateStripeSessionResponse>({
      action: ({ client }) =>
        client.post('stripe/sessions', { json: { planId: planId } }).json<CreateStripeSessionResponse>(),
      onSuccess: (response: CreateStripeSessionResponse) => {
        ReactGA.event('begin_checkout');
        window.location.replace(response.stripeSession.url);
      },
      errorMessage:
        'Failed to create Stripe session. Make sure you have configured your Stripe API keys for this environment.',
    });

  const listPlans = async () =>
    executeApiAction<Plan[]>({
      action: async ({ client }) => (await client.get('stripe/plans').json<StripePlansReponse>()).plans,
      errorMessage: 'Failed to fetch products.',
    });

  const configureAccount = async () =>
    await executeApiAction<StripeAccountLinkResponse>({
      action: async ({ client }) => await client.post('stripe/accounts').json<StripeAccountLinkResponse>(),
      onSuccess: ({ url }: StripeAccountLinkResponse) => {
        window.location.replace(url);
      },
    });

  const endTrial = async () =>
    await executeApiAction<StripeAccountLinkResponse>({
      action: async ({ client }) => await client.delete('stripe/trial'),
      successMessage: 'Subscription updated. Please reload the page.',
    });

  return {
    manageSubscriptions: useCallback(manageSubscriptions, [executeApiAction]),
    activateSubscription: useCallback(activateSubscription, [executeApiAction]),
    listPlans: useCallback(listPlans, [executeApiAction]),
    configureAccount: useCallback(configureAccount, [executeApiAction]),
    endTrial: useCallback(endTrial, [executeApiAction]),
  };
};
