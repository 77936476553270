import { useCallback } from 'react';
import { AppInvitation } from '../types';
import { useApiActions } from './api-actions';

type CreateAppInvitationResponse = {
  appInvitation: AppInvitation;
};

export const useAppUserActions = () => {
  const { executeApiAction } = useApiActions();

  const createAppInvitation = async ({ appId }: { appId: string }) =>
    await executeApiAction<AppInvitation>({
      action: async ({ client }) =>
        (
          await client.post('app-invitations', { json: { appId } }).json<CreateAppInvitationResponse>()
        ).appInvitation,
      errorMessage: 'Failed to create app invitation',
    });

  const deleteAppUser = async (appId: string, userId: string) =>
    await executeApiAction<void>({
      action: async ({ client }) => {
        await client.delete(`apps/${appId}/users/${userId}`);
      },
      errorMessage: 'Failed to delete user',
    });

  const updateAppUser = async (appId: string, userId: string, role: string) =>
    await executeApiAction<void>({
      action: async ({ client }) => {
        await client.put(`apps/${appId}/users/${userId}`, { json: { role } });
      },
      errorMessage: 'Failed to set role',
      successMessage: 'Role updated',
    });

  return {
    createAppInvitation: useCallback(createAppInvitation, [executeApiAction]),
    deleteAppUser: useCallback(deleteAppUser, [executeApiAction]),
    updateAppUser: useCallback(updateAppUser, [executeApiAction]),
  };
};
