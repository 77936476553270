import { ChatGPTRole, ChatGptModel, StepType, TextInputType } from '@packages/clevis';
import { Template } from '../types';

export const motivationalAi: Template = {
  title: 'Motivational Speech Creator',
  description:
    'Need that extra motivational kick? This app will give you just what you need by creating a motivational speech for the user!',
  appConfig: {
    steps: [
      {
        id: 'input',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter your name',
          inputType: TextInputType.INPUT,
        },
      },
      {
        id: 'input_event',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'What is your goal?',
          inputType: TextInputType.INPUT,
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.SYSTEM,
              content:
                'I want you to act as a motivational speaker. Put together words that inspire action and make people feel empowered to do something beyond their abilities. Make sure what you say resonates with your audience, giving them an incentive to work on their goals and strive for better possibilities.',
            },
            {
              role: ChatGPTRole.USER,
              content:
                'Create a motivational speech for a person named {{ steps.input.output }} with the goal {{ steps.input_event.output }}',
            },
          ],
        },
      },
      {
        id: 'output',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '{{ steps.gpt.output }}',
        },
      },
    ],
  },
};
