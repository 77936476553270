import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { AppGenerator } from '../apps/AppGenerator';
import { useAppsContext } from '../../providers';

type AssistantDrawerProps = { isOpen: boolean; onClose: () => void };

export const AssistantDrawer: React.FC<AssistantDrawerProps> = ({ isOpen, onClose }) => {
  const { selectedApp } = useAppsContext();
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <DrawerCloseButton />

        <DrawerHeader>Generate with AI assistant</DrawerHeader>
        <DrawerBody>
          <AppGenerator appId={selectedApp?.id} onComplete={onClose} />
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};
