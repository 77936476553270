import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { MdAddShoppingCart, MdOutlineOpenInNew, MdUpgrade } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { SoftCard } from '../../components/generic/SoftCard';
import { useSubscriptionActions } from '../../hooks';
import { useAuthContext } from '../../providers';

export const BillingSettingsPage: React.FC = () => {
  const { user } = useAuthContext();
  const { manageSubscriptions, endTrial } = useSubscriptionActions();
  const navigate = useNavigate();

  return (
    <Flex flexDir="column" gap={4}>
      {user?.isTrialing && (
        <SoftCard>
          <Flex justifyContent="space-between" alignItems="center" gap={8}>
            <Box>
              <Heading as="h3" size="sm" mb={2}>
                End trial
              </Heading>
              <Text>
                End your trial early and upgrade to a paid plan. This will charge your provided payment method and
                unlock the full amount of credits for your chosen plan.
              </Text>
            </Box>

            <Box>
              <Button rightIcon={<MdUpgrade />} onClick={endTrial}>
                Upgrade
              </Button>
            </Box>
          </Flex>
        </SoftCard>
      )}

      <SoftCard>
        <Flex justifyContent="space-between" alignItems="center" gap={8}>
          <Box>
            <Heading as="h3" size="sm" mb={2}>
              Manage subscription
            </Heading>
            <Text>View, edit or cancel your subscription</Text>
          </Box>

          {user?.hasActiveSubscription ? (
            <Button rightIcon={<MdAddShoppingCart />} onClick={manageSubscriptions}>
              Manage
            </Button>
          ) : (
            <Button leftIcon={<MdOutlineOpenInNew />} onClick={() => navigate('/onboarding')}>
              Manage
            </Button>
          )}
        </Flex>
      </SoftCard>
    </Flex>
  );
};
