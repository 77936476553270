import { ErrorNames } from './error-names';

export class RunnerError extends Error {
  public details?: string;
  public debug?: string;
  public name: string;
  public stepId?: string;

  constructor({
    name,
    message,
    details,
    stepId,
    debug,
  }: {
    name: ErrorNames;
    message: string;
    details?: string;
    stepId?: string;
    debug?: string;
  }) {
    super(message);
    this.details = details;
    this.debug = debug;
    this.name = name;
    this.stepId = stepId;
  }
}
