import { SendEmailStepTypeConfig, StepConfig } from '@packages/clevis';
import { FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { TemplateArea } from './TemplateArea';

export const SendEmailStep = () => {
  const { control } = useFormContext<StepConfig<SendEmailStepTypeConfig>>();

  return (
    <VStack spacing="4">
      <FormControl>
        <FormLabel mt={2} htmlFor="address" fontSize="sm">
          Address
        </FormLabel>
        <TemplateArea
          id="address"
          name="config.addresses.0"
          control={control}
          fontSize="sm"
          singleLine
          variableTypes={['variables', 'custom', 'contexts']}
        />
      </FormControl>
      <FormControl>
        <FormLabel mt={2} htmlFor="subject" fontSize="sm">
          Subject
        </FormLabel>
        <TemplateArea
          id="subject"
          name="config.subject"
          control={control}
          fontSize="sm"
          singleLine
          variableTypes={['variables', 'custom', 'contexts']}
        />
      </FormControl>
      <FormControl>
        <FormLabel mt={2} htmlFor="content" fontSize="sm">
          Content
        </FormLabel>
        <TemplateArea
          id="content"
          name="config.content"
          control={control}
          fontSize="sm"
          variableTypes={['variables', 'custom', 'contexts']}
        />
      </FormControl>
    </VStack>
  );
};
