import { Button, useDisclosure, Text, Heading, Badge, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import { FileDrawer } from '../../components/drawers';
import { PaginatedTable } from '../../components/generic/PaginatedTable';
import { File } from '../../types/file';
import { useEffect, useState } from 'react';
import { useApiActions } from '../../hooks/api-actions';
import { useAppsContext } from '../../providers';
import { MdDelete, MdFileUpload } from 'react-icons/md';
import { SoftCard } from '../../components/generic/SoftCard';
import { ConfirmButton } from '../../components/generic/ConfirmButton';

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case 'UPLOADED':
      return <Badge colorScheme="purple">Uploaded</Badge>;
    case 'PROCESSING':
      return <Spinner />;
    case 'PROCESSING_COMPLETED':
      return <Badge colorScheme="purple">Processing Completed</Badge>;
    case 'PROCESSING_FAILED':
      return <Badge colorScheme="yelow">Processing Failed</Badge>;
  }
};

export const FilesPage: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { selectedApp } = useAppsContext();
  const { executeApiAction } = useApiActions();
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const deleteFile = async (id: string) => {
    await executeApiAction<{ files: File[] }>({
      action: async ({ client }) => client.delete(`apps/${selectedApp?.id}/files/${id}`).json(),
      errorMessage: 'Failed to delete file',
    });
    await fetchFiles();
  };

  const fetchFiles = async () => {
    setIsLoading(true);
    const data = await executeApiAction<{ files: File[] }>({
      action: async ({ client }) => client.get(`apps/${selectedApp?.id}/files`).json(),
      errorMessage: 'Failed to load files',
    });
    if (data) setFiles(data.files);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FileDrawer isOpen={isOpen} onClose={onClose} onUploadComplete={fetchFiles} />
      <SoftCard mb={6}>
        <Heading size="md">Files</Heading>
        <Text>Upload files to give any ChatGPT steps in your app access to the file content.</Text>
      </SoftCard>
      <Alert mb={4} status="info">
        <AlertIcon />
        Once a file is uploaded, it can take a while for it to be processed. When the status column shows "Processing
        Completed", the file content is ready to be used by your app.
      </Alert>
      <PaginatedTable<File>
        columns={[
          { id: 'name', title: 'Name', render: ({ row }) => row.name },
          {
            id: 'status',
            title: 'Status',
            render: ({ row }) => <StatusBadge status={row.status} />,
          },
          {
            id: 'actions',
            title: '',
            render: ({ row }) => (
              <ConfirmButton
                icon={<MdDelete />}
                body={'Are you sure that you want to delete this file?'}
                cta={'Delete'}
                onClick={() => deleteFile(row.id)}
              ></ConfirmButton>
            ),
          },
        ]}
        data={files}
        totalRecords={files.length}
        isLoading={isLoading}
        onChangePage={() => {}} // No pagination yet
        onRefresh={fetchFiles}
        cta={[
          <Button leftIcon={<MdFileUpload />} onClick={onOpen}>
            Upload file
          </Button>,
        ]}
      />
    </>
  );
};
