import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../providers';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { MdApps, MdArrowDropDown, MdMenu, MdSchool, MdSettings } from 'react-icons/md';
import { SignOutButton } from '../authentication/SignOutButton';
import { IconType } from 'react-icons';
import { PageHeading } from '../generic/PageHeading';
import { FaSlack } from 'react-icons/fa';

interface TopBarProps {
  onOpenDrawer?: () => void;
  title?: string;
  icon?: IconType;
}

export const TopBar: React.FC<TopBarProps> = ({ title, icon, onOpenDrawer }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  return (
    <Flex ml={{ base: 0 }} px={{ base: 4, md: 4 }} py={4} gap={4} alignItems="center" width="full">
      {onOpenDrawer && (
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          aria-label="open menu"
          icon={<MdMenu />}
          onClick={onOpenDrawer}
        />
      )}
      {(title || icon) && <Flex>{title && <PageHeading title={title} icon={icon} />}</Flex>}

      <Flex flexGrow={1} justifyContent="flex-end" alignItems="center" gap={4}>
        <Flex alignItems="center" gap={4}>
          <Tooltip label="Slack Community">
            <IconButton
              color="gray.200"
              _hover={{
                backgroundColor: 'whiteAlpha.100',
              }}
              aria-label="Join Slack Community"
              icon={<FaSlack />}
              as={Link}
              size="sm"
              variant="ghost"
              target="_blank"
              to="https://join.slack.com/t/clevisworkspace/shared_invite/zt-21kdhqzf9-pHRnHsiomG76QTv2OtJC5g"
            />
          </Tooltip>
          <Tooltip label="Tutorials">
            <IconButton
              color="gray.200"
              _hover={{
                backgroundColor: 'whiteAlpha.100',
              }}
              aria-label="tutorials"
              icon={<MdSchool />}
              as={Link}
              size="sm"
              variant="ghost"
              to="/tutorials"
            />
          </Tooltip>

          <Menu>
            <MenuButton transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size="sm" {...(user && { name: `${user.firstName} ${user.lastName}`, src: user.picture })} />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{user && `${user.firstName} ${user.lastName}`}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <MdArrowDropDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  navigate('/apps');
                }}
                icon={<MdApps />}
              >
                My Apps
              </MenuItem>

              <MenuItem onClick={() => navigate('/settings')} icon={<MdSettings />}>
                Settings
              </MenuItem>
              <MenuDivider />
              <SignOutButton>Sign out</SignOutButton>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
