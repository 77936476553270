import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage, VStack } from '@chakra-ui/react';

import { FileInput } from './inputs/FileInput';

interface FormProps {
  file: {
    key: string;
    name: string;
  };
}

interface FileUploadFormProps {
  onSubmit: (data: FormProps) => void;
}

export const FileUploadForm: React.FC<FileUploadFormProps> = ({ onSubmit }) => {
  const {
    control,
    formState: { errors },
  } = useForm<FormProps>();

  return (
    <form noValidate>
      <VStack>
        <FormControl isInvalid={!!errors.file}>
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange } }) => (
              <FileInput
                level={'private'}
                accept=".pdf,.csv"
                onUpload={(file) => {
                  onChange({ file });
                  onSubmit({ file });
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.file?.message}</FormErrorMessage>
        </FormControl>
      </VStack>
    </form>
  );
};
