import React from 'react';
import { Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { PageHeading } from '../components/generic/PageHeading';
import { MdSchool } from 'react-icons/md';

export const TutorialsPage: React.FC = () => {
  return (
    <AuthenticatedLayout>
      <PageHeading title="Tutorials" icon={MdSchool} />
      <SimpleGrid mt={8} columns={2} gap={8}>
        <SoftCard>
          <Heading as="h2" size="md" mb={4}>
            Recipe Creator
          </Heading>
          <Text mb="4">
            Learn how to build a Recipe Creator app where the user inputs their diet and ChatGPT generates a recipe.
          </Text>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yQI_kSd8hec?si=uTfspNhX6YST8yFT"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </SoftCard>
        <SoftCard>
          <Heading as="h2" size="md" mb={4}>
            News Summarizer
          </Heading>
          <Text mb="4">
            Learn how to build a News Summarizer app that fetches the latest news about a given subject, summarizes it
            with ChatGPT and sends the result to an email address.
          </Text>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-v8FaDsk5aA?si=0Q8a502xRrC_C0Km"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </SoftCard>
      </SimpleGrid>
    </AuthenticatedLayout>
  );
};
