import { Box, FormControl, Input, Link, Spinner, Text } from '@chakra-ui/react';
import { GeolocationStepTypeConfig, StepConfig } from '@packages/clevis/src';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { RunAppInput } from '../AppRunner';
import { useEffect, useState } from 'react';
import { AlertBox } from '../../generic/AlertBox';

export const GeolocationInput = ({
  step,
  register,
  setValue,
}: {
  step: StepConfig<GeolocationStepTypeConfig>;
  register: UseFormRegister<RunAppInput>;
  setValue: UseFormSetValue<RunAppInput>;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const loadPosition = () => {
    setError('');
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const result = { latitude: position.coords.latitude, longitude: position.coords.longitude };
        setValue(step.id, result);
        setLoading(false);
      },
      (err: GeolocationPositionError) => {
        setLoading(false);
        setError(err.message);
      }
    );
  };

  useEffect(() => {
    loadPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl isRequired>
      <Input type="hidden" {...register(step.id, { required: 'Your location is required' })} />

      {loading && (
        <Box>
          <Spinner />
          <Text>Waiting for location..</Text>
        </Box>
      )}

      {error && (
        <AlertBox
          status="error"
          title="Failed to load location"
          description={
            <Text>
              Could not load your position, click <Link onClick={loadPosition}>here</Link> to try again.
            </Text>
          }
        />
      )}
    </FormControl>
  );
};
