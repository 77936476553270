import { AddIcon } from '@chakra-ui/icons';
import { Flex, Menu, MenuButton, MenuItem, MenuList, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { getSecrets } from './helpers';
import { useAppsContext } from '../../../providers';

type SecretsMenuProps = {
  onSelect: ({ key, value }: { key: string; value: string }) => void;
};

export const SecretsMenu: React.FC<SecretsMenuProps> = ({ onSelect }) => {
  const { selectedApp } = useAppsContext();

  const secrets = getSecrets(selectedApp?.secrets || []);

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton as={Tag} _hover={{ cursor: 'pointer' }} variant="outline">
            <Flex alignItems="center">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Secrets</TagLabel>
            </Flex>
          </MenuButton>
          <MenuList>
            {secrets.length > 0 ? (
              secrets.map((secret) => (
                <MenuItem
                  fontSize="sm"
                  key={secret.value}
                  onClick={(event) => {
                    onClose();
                    onSelect({ key: secret.text, value: secret.value });
                  }}
                >
                  {secret.text}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No secrets</MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
