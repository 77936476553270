import React, { useEffect, useState } from 'react';
import {
  Heading,
  Button,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  VStack,
  Card,
  Flex,
  List,
  ListItem,
  ListIcon,
  Box,
  Link,
  Spacer,
} from '@chakra-ui/react';

import { AuthenticatedLayout } from '../layouts';
import { useNavigate, useParams } from 'react-router-dom';
import { useSubscriptionActions } from '../hooks';
import { MdCheckCircle, MdRocketLaunch } from 'react-icons/md';
import { Plan, PlanId, User } from '../types';
import { useAppConfigContext, useAuthContext } from '../providers';

type PlanDescriptionMap = {
  [key in PlanId]: {
    title: string;
    description: string;
    price: number;
    features: Array<string>;
  };
};

const plans: PlanDescriptionMap = {
  FREE: {
    title: 'Free',
    description: 'Build an app with minimal usage',
    price: 0,
    features: ['250 credits', '1 team member', 'Limited support'],
  },
  STARTER: {
    title: 'Starter',
    description: 'Get started releasing your first app',
    price: 29,
    features: ['20 000 credits', 'Customized branding', 'API access', '1 team member', 'Slack community'],
  },
  PROFESSIONAL: {
    title: 'Professional',
    description: 'Scale your apps together with your team',
    price: 59,
    features: ['50 000 credits', 'Customized branding', 'API access', '5 team members', 'Slack community'],
  },
};

export interface PricingCardData {
  features: string[];
  name: string;
  price: string;
}

export const PricingCard = ({
  plan,
  onActivate,
  user,
  isLoading,
}: {
  plan: Plan;
  onActivate: (id: string) => Promise<void>;
  user: User;
  isLoading: boolean;
}) => {
  if (!(plan.id in plans)) return null;
  const { features, title, price } = plans[plan.id as keyof typeof plans];
  const isCurrentPlan = user.planId === plan.id;

  return (
    <Card background={'gray.900'} display="flex" p={6} overflow="hidden" mb={8} w={{ base: '100%', lg: '50%' }}>
      <VStack>
        <Heading size="lg">{title}</Heading>
        <Text>7 day free trial, cancel any time.</Text>
      </VStack>
      <Flex align="flex-end" justify="center" fontWeight="extrabold" my="8">
        <Text fontWeight="inherit" fontSize="4xl" lineHeight={'1.4em'} mr={0.8}>
          $
        </Text>
        <Heading size="3xl" fontWeight="inherit" lineHeight="0.9em">
          {price}
        </Heading>
        <Text fontWeight="inherit" fontSize="2xl" lineHeight={'2.2em'} ml={1}>
          / mo
        </Text>
      </Flex>
      <List spacing={2} mb="8">
        {features.map((feature, index) => (
          <ListItem fontWeight="medium" key={index}>
            <ListIcon color="green.200" fontSize="xl" as={MdCheckCircle} marginEnd={2} />
            {feature}
          </ListItem>
        ))}
      </List>
      <Spacer />
      <Button
        size="lg"
        w="full"
        fontWeight="extrabold"
        py={{ md: '8' }}
        leftIcon={isCurrentPlan ? <MdCheckCircle /> : <MdRocketLaunch />}
        onClick={() => onActivate(plan.id)}
        isDisabled={isCurrentPlan}
        isLoading={isLoading}
        _hover={isCurrentPlan ? { background: 'gradient' } : {}}
      >
        {isCurrentPlan ? 'Current' : user.freeTrialActivated ? `Get ${title}` : 'Start free trial'}
      </Button>
    </Card>
  );
};

export const SubscribePage: React.FC = () => {
  const { user } = useAuthContext();
  const { activateSubscription, listPlans } = useSubscriptionActions();
  const [plans, setPlans] = useState<Plan[]>([]);
  const { config } = useAppConfigContext();
  const [isLoading, setIsLoading] = useState(false);
  const { status } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getPlans = async () => {
      const data = await listPlans();
      setPlans(data || []);
    };
    getPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'success') {
      navigate('/');
    }
  }, [status, navigate]);

  const onActivate = async (id: string) => {
    try {
      setIsLoading(true);
      await activateSubscription(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {config.stripeTestCardEnabled && (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>Test payments enabled</AlertTitle>
          <AlertDescription>
            Test mode for payments is enabled and no real charges will be made. The checkout will be prefilled with a
            test card that you can use for testing purposes.
          </AlertDescription>
        </Alert>
      )}
      {status === 'canceled' && (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle>Payment failed</AlertTitle>
          <AlertDescription>Something went wrong while processing your payment. Please try again.</AlertDescription>
        </Alert>
      )}
      <AuthenticatedLayout>
        <VStack maxW="960px" spacing={4} alignSelf={'center'} textAlign="center">
          <Box mb={4}>
            <Text color="brand.400" fontWeight="bold">
              Get started
            </Text>
            <Heading>{`Welcome to Clevis${user ? `, ${user.firstName}` : ''}!`}</Heading>
            <Text>
              Questions? Join us in our <Link isExternal>Slack community</Link>!
            </Text>
          </Box>
          <Box
            textAlign={'left'}
            w="100%"
            mb="6"
            p="4"
            borderRadius="6"
            backgroundImage="linear(to-r, #FC4760, #fd21a4)"
          >
            ✨ <strong>Limited Offer:</strong> We custom-build your app upon request when you sign up for a paid plan.
            Limited to the first 50 customers. Don't miss out!
          </Box>
          <Flex w="100%" justifyContent={'center'} columnGap={6} flexDir={{ base: 'column', lg: 'row' }}>
            {user &&
              plans.map((plan) => (
                <PricingCard key={plan.id} plan={plan} onActivate={onActivate} user={user} isLoading={isLoading} />
              ))}
          </Flex>
        </VStack>
      </AuthenticatedLayout>
    </>
  );
};
