import React from 'react';
import { FormControl, FormLabel, VStack, Text, Link } from '@chakra-ui/react';
import { DisplayOutputStepTypeConfig, StepConfig } from '@packages/clevis';
import { useFormContext } from 'react-hook-form';
import { TemplateArea } from './TemplateArea';

export const DisplayOutputStep = () => {
  const { control } = useFormContext<StepConfig<DisplayOutputStepTypeConfig>>();

  return (
    <VStack spacing="4">
      <FormControl>
        <FormLabel mt={2} htmlFor="output" fontSize="sm">
          Output
        </FormLabel>
        <Text mb={2} fontSize={'xs'}>
          Add new content or reference outputs from previous steps. HTML formatting and{' '}
          <Link href="https://liquidjs.com/index.html" isExternal>
            Liquid syntax
          </Link>{' '}
          is allowed.
        </Text>
        <TemplateArea
          fontSize="sm"
          name="config.output"
          control={control}
          variableTypes={['variables', 'contexts', 'custom']}
        />
      </FormControl>
    </VStack>
  );
};
