import { Outlet, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from '../providers';

export const AuthenticatedRoute = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate({
        pathname: '/sign-in',
        search: createSearchParams({
          continue: `${location.pathname + location.search}`,
        }).toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return user && <Outlet />;
};
