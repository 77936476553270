import React from 'react';
import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  Select,
  Button,
  Switch,
  FormHelperText,
  Link,
  Flex,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { HTTPRequestStepTypeConfig, StepConfig } from '@packages/clevis';
import { MdAdd, MdDelete } from 'react-icons/md';
import { TemplateArea } from './TemplateArea';

export const HTTPRequestStep = () => {
  const { register, control, watch } = useFormContext<StepConfig<HTTPRequestStepTypeConfig>>();
  const { fields, append, remove } = useFieldArray<StepConfig<HTTPRequestStepTypeConfig>>({
    control,
    rules: { minLength: 1 },
    name: 'config.headers',
  });

  const headers = watch('config.headers');

  return (
    <VStack spacing="4">
      <FormControl>
        <FormLabel mt={2} htmlFor="method" fontSize="sm">
          Method
        </FormLabel>
        <FormControl isRequired>
          <Select
            {...register('config.method', { required: 'Select a method' })}
            defaultValue="GET"
            mb="2"
            fontSize="sm"
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </Select>
        </FormControl>
        <FormLabel mt={2} htmlFor="url" fontSize="sm">
          URL
        </FormLabel>

        <FormControl isRequired>
          <TemplateArea
            id="url"
            name="config.url"
            fontSize="sm"
            control={control}
            singleLine
            variableTypes={['variables', 'contexts', 'custom']}
          />
        </FormControl>

        <FormLabel mt={2} htmlFor="body" fontSize="sm">
          Body
        </FormLabel>
        <TemplateArea
          name="config.body"
          fontSize="sm"
          placeholder="{}"
          control={control}
          variableTypes={['variables', 'secrets', 'custom', 'contexts']}
        />
        <FormLabel mt={2} htmlFor="headers">
          Headers
        </FormLabel>
        {fields.map((_, index) => (
          <React.Fragment key={index}>
            <Flex flexDirection="column" gap={2} my={4}>
              <Input fontSize="sm" placeholder="Header" {...register(`config.headers.${index}.header`)} />
              <TemplateArea
                name={`config.headers.${index}.value`}
                placeholder="Value"
                fontSize="sm"
                control={control}
                singleLine
                variableTypes={['variables', 'secrets', 'custom', 'contexts']}
              />
            </Flex>
          </React.Fragment>
        ))}

        <Button onClick={() => append({ header: '', value: '' })} iconSpacing={0} leftIcon={<MdAdd />} size="sm" />
        {headers && headers.length > 0 && (
          <Button
            iconSpacing={0}
            leftIcon={<MdDelete />}
            ml="4"
            colorScheme="red"
            onClick={() => remove(headers.length - 1)}
            size="sm"
          />
        )}

        <FormLabel mt={2} htmlFor="parseText">
          Parse text
        </FormLabel>
        <Controller
          control={control}
          name="config.parseText"
          render={({ field: { onChange, onBlur, name, value, ref } }) => (
            <Switch
              id="parseText"
              size="lg"
              name={name}
              mb="4"
              onBlur={onBlur}
              onChange={onChange}
              isChecked={value}
              ref={ref}
            />
          )}
        />

        <FormHelperText>
          Enable this to get the response as text, for example if you want to scrape the text content from a website.
        </FormHelperText>

        <FormLabel mt={2} htmlFor="querySelector">
          Selector
        </FormLabel>
        <FormControl>
          <Input {...register('config.querySelector')} />
        </FormControl>
        <FormHelperText>
          Optionally enter a{' '}
          <Link
            isExternal
            href="https://developer.mozilla.org/en-US/docs/Web/API/Document_object_model/Locating_DOM_elements_using_selectors"
          >
            selector
          </Link>{' '}
          to narrow down the HTML response text. E.g. "#main-content" to get the text from an element with the ID
          #main-content.
        </FormHelperText>
      </FormControl>
    </VStack>
  );
};
