import React, { useEffect, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { SoftCard } from '../../components/generic/SoftCard';
import { useAppsContext } from '../../providers';
import { useApiActions } from '../../hooks/api-actions';
import { Run, RunStatus } from '../../types/run';
import { PaginatedTable } from '../../components/generic/PaginatedTable';
import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export const ListRunsPage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { executeApiAction } = useApiActions();
  const [data, setData] = useState<{ runs: Run[]; total: number }>({ runs: [], total: 0 });
  const [runToDisplay, setRunToDisplay] = useState<Run>();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const pageSize = 10;

  const fetchRuns = async ({ page, size }: { page: number; size: number }) => {
    setLoading(true);
    await executeApiAction<{ runs: Run[]; total: number }>({
      action: async ({ client }) =>
        await client
          .get(`apps/${selectedApp?.id}/runs`, {
            searchParams: {
              page,
              size,
            },
          })
          .json<{ runs: Run[]; total: number }>(),
      onSuccess: (data: { runs: Run[]; total: number }) => {
        setData(data);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
      errorMessage: 'Failed to load runs',
    });
  };

  useEffect(() => {
    fetchRuns({ page: 0, size: pageSize });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBadge = (status: RunStatus) => {
    switch (status) {
      case RunStatus.CREATED:
        return <Badge colorScheme="purple">Created</Badge>;
      case RunStatus.IN_PROGRESS:
        return <Spinner />;
      case RunStatus.INPUT_REQUIRED:
        return <Badge colorScheme="yellow">Input Required</Badge>;
      case RunStatus.FAILED:
        return <Badge colorScheme="red">Failed</Badge>;
      case RunStatus.COMPLETED:
        return <Badge colorScheme="green">Succeeded</Badge>;
    }
  };

  return (
    <>
      <SoftCard>
        <PaginatedTable<Run>
          columns={[
            { id: 'status', title: 'Status', render: ({ row }) => getBadge(row.status) },
            { id: 'id', title: 'ID', render: ({ row }) => row.id },
            {
              id: 'runtime',
              title: 'Runtime',
              render: ({ row }) =>
                row.completedAt
                  ? `${
                      Math.round((new Date(row.completedAt).getTime() - new Date(row.createdAt).getTime()) / 100) / 10
                    }s`
                  : 'n/a',
            },
            {
              id: 'credits',
              title: 'Used Credits',
              render: ({ row }) => row.consumedCredits,
            },
            {
              id: 'created',
              title: 'Created',
              render: ({ row }) => `${formatDistanceToNow(new Date(row.createdAt))} ago`,
            },
            {
              id: 'actions',
              render: ({ row }) => (
                <>
                  <IconButton
                    aria-label="View"
                    icon={<MdRemoveRedEye />}
                    colorScheme="gray"
                    onClick={() => {
                      setRunToDisplay(row);
                      onOpen();
                    }}
                  />
                </>
              ),
            },
          ]}
          data={data.runs}
          totalRecords={data.total}
          isLoading={loading}
          pageSize={pageSize}
          onChangePage={fetchRuns}
          onRefresh={fetchRuns}
          emptyMessage="No runs to display"
        />
      </SoftCard>

      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent overflow="scroll">
          <DrawerCloseButton />
          <DrawerHeader fontSize="lg" fontWeight="bold">
            Run
          </DrawerHeader>
          <DrawerBody>
            <Flex gap={4} flexDir="column" maxW="100%">
              <Flex flexDir="column">
                <Text fontSize="sm" as="b">
                  ID
                </Text>
                <Text fontSize="sm">{runToDisplay?.id}</Text>
              </Flex>
              <Flex flexDir="column">
                <Text fontSize="sm" as="b">
                  Status
                </Text>
                <Box>
                  <Badge>{runToDisplay?.status}</Badge>
                </Box>
              </Flex>
              <Flex flexDir="column">
                <Text fontSize="sm" as="b">
                  Runtime
                </Text>
                <Box>
                  <Text fontSize="sm">
                    {runToDisplay?.completedAt
                      ? `${
                          Math.round(
                            (new Date(runToDisplay?.completedAt).getTime() -
                              new Date(runToDisplay?.createdAt).getTime()) /
                              100
                          ) / 10
                        }s`
                      : 'n/a'}
                  </Text>
                </Box>
              </Flex>
              <Flex flexDir="column">
                <Text fontSize="sm" as="b">
                  Input
                </Text>
                <pre
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {JSON.stringify(runToDisplay?.input, null, 2)}
                </pre>
              </Flex>

              {runToDisplay?.error && (
                <Flex flexDir="column">
                  <Text as="b" fontSize="sm">
                    Error
                  </Text>

                  <pre
                    style={{
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                    }}
                  >
                    {JSON.stringify(runToDisplay?.error, null, 2)}
                  </pre>
                </Flex>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
