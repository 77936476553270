import { Box, Image, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const Logo = () => (
  <Box w={42}>
    <Link to="/">
      <Image id="logo" src={useColorModeValue('/logo-dark.svg', '/logo-light.svg')} />
    </Link>
  </Box>
);
