export const contextVariables = [
  {
    value: '"now" | date: "%d"',
    text: 'Date',
  },
  {
    value: '"now" | date: "%u"',
    text: 'Day number',
  },
  {
    value: '"now" | date: "%A"',
    text: 'Day',
  },
  {
    value: '"now" | date: "%m"',
    text: 'Month number',
  },
  {
    value: '"now" | date: "%B"',
    text: 'Month',
  },
  {
    value: '"now" | date: "%D"',
    text: 'Year',
  },
];
