import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppsContext } from '../providers';
import { useEffect } from 'react';
import { SidebarLayout } from '../layouts';

export const SelectedAppRoute = () => {
  const { selectedApp } = useAppsContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedApp) {
      navigate('/apps');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedApp]);

  return (
    <SidebarLayout>
      <Outlet />
    </SidebarLayout>
  );
};
