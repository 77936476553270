import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  useToast,
  Text,
  Heading,
  Spinner,
  Divider,
  Spacer,
  Container,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../layouts';
import { AppRunner } from '../components/apps/AppRunner';
import { App } from '../types';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';
import { useApiActions } from '../hooks/api-actions';
import { Purchase } from '../types/purchase';
import { BuyButton } from '../components/apps/BuyButton';
import React from 'react';
import { getBrandButtonProps } from '../components/apps/utils';
import { RunStatus } from '../types/run';
import { ColorMode } from '../components/generic/ColorMode';

type PublicAppResponse = {
  app: App;
  purchase?: Purchase;
};

type FetchAppError = {
  title: string;
  description: string;
};

export const AppPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [app, setApp] = useState<App>();
  const [error, setError] = useState<FetchAppError | undefined>();
  const [purchase, setPurchase] = useState<Purchase>();
  const toast = useToast();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { executeApiAction } = useApiActions();

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (!slug) return;

    const fetchApp = async () => {
      const appSearchParams = createSearchParams();

      const p = searchParams.get('p');
      if (p) appSearchParams.set('purchaseId', p);

      setIsLoading(true);
      await executeApiAction<PublicAppResponse>({
        action: ({ publicClient }) =>
          publicClient.get(`public/apps/${slug}`, { searchParams: appSearchParams }).json<PublicAppResponse>(),
        onSuccess: (response: PublicAppResponse) => {
          setApp(response.app);
          if (response.purchase) {
            setPurchase(response.purchase);
          }
          setIsLoading(false);
        },
        onError: (err: any) => {
          if (err.name === 'APP_DOES_NOT_EXIST') {
            setError({ title: '404', description: 'It seems like this app does not exist' });
          } else {
            setError({
              title: 'Failed to load app',
              description: 'Something went wrong when loading the app, please try again',
            });
          }

          setIsLoading(false);
        },
      });
    };
    fetchApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <DefaultLayout>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" flexGrow="1">
          <Spinner size="lg" />
        </Flex>
      </DefaultLayout>
    );
  }

  if (error) {
    return (
      <DefaultLayout>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" flexGrow="1">
          <Heading as="h2">{error.title}</Heading>
          <Text>{error.description}</Text>
        </Flex>
      </DefaultLayout>
    );
  }

  if (app) {
    return (
      <DefaultLayout background={app.backgroundColor}>
        <Helmet>
          <title>{app.name} | Clevis</title>
          <meta name="description" content={app.description} />
        </Helmet>

        <Container maxW="4xl" px={{ base: 0, md: 4 }}>
          <Flex flexDir="column" color={app.textColor ?? 'inherit'} py={{ base: 0, md: 8 }}>
            <ColorMode color={app.backgroundColor}>
              {purchase && (
                <Box>
                  <Heading size="sm">Link</Heading>
                  <Text mb="2">
                    Your unique link that lets you run {app.name}. Copy it if you want to access the app later.
                  </Text>
                  <Flex align="center" mb="4">
                    <Input value={window.location.href} isReadOnly={true} pr="4rem" />
                    <Button {...getBrandButtonProps(app.brandColor)} ml="1rem" onClick={handleCopy}>
                      Copy
                    </Button>
                  </Flex>
                  <Heading size="sm">Runs</Heading>
                  <Text>
                    You have <strong>{purchase.remainingRuns}</strong> runs left out of{' '}
                    <strong>{purchase.totalRuns} </strong>
                    total.
                  </Text>
                  <Divider mt="4" mb="4" />
                </Box>
              )}
              {app.paymentsEnabled && (!purchase || purchase.remainingRuns === 0) && (
                <>
                  <Heading mb={2}>{app.name}</Heading>
                  <Text fontSize="lg" mb="8">
                    {app.description}
                  </Text>
                  <BuyButton
                    {...getBrandButtonProps(app.brandColor)}
                    appId={app.id}
                    price={app.price}
                    runsPerPurchase={app.runsPerPurchase}
                  />
                </>
              )}
              {(!app.paymentsEnabled || purchase?.remainingRuns) && (
                <AppRunner
                  onStateUpdate={(_state, status) => {
                    if (status === RunStatus.COMPLETED && purchase) {
                      setPurchase({ ...purchase, remainingRuns: purchase.remainingRuns - 1 });
                    }
                  }}
                  purchaseId={purchase?.id}
                  app={app}
                  isPublic={true}
                />
              )}
            </ColorMode>
            <Spacer mb="8" />
          </Flex>
        </Container>
      </DefaultLayout>
    );
  }
};
