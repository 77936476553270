import React from 'react';

import { AuthenticatedLayout } from '../../layouts';
import { SoftCard } from '../../components/generic/SoftCard';
import { AppGenerator } from '../../components/apps/AppGenerator';

export const GenerateAppPage: React.FC = () => (
  <AuthenticatedLayout>
    <SoftCard title="Generate with AI assistant 🪄">
      <AppGenerator />
    </SoftCard>
  </AuthenticatedLayout>
);
