import React from 'react';
import { Input, Button, FormControl, FormErrorMessage, Textarea, FormLabel, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { AuthenticatedLayout } from '../../layouts';
import { MdChevronRight } from 'react-icons/md';
import { SoftCard } from '../../components/generic/SoftCard';
import { useAppActions } from '../../hooks';
import { useNavigate } from 'react-router-dom';

type FormValues = {
  name: string;
  description?: string;
};

export const CreateAppPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const navigate = useNavigate();
  const { createApp } = useAppActions();

  const onSubmit = async ({ name, description }: { name: string; description?: string }) => {
    await createApp({ name, description });
  };

  return (
    <AuthenticatedLayout>
      <SoftCard title="Create App">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4} alignItems="flex-start">
            <Button variant="outline" onClick={() => navigate('/apps/generate')}>
              Not sure? Use our AI assistant! 🪄
            </Button>
            <FormControl isRequired>
              <FormLabel htmlFor="name" fontSize="sm">
                Name
              </FormLabel>
              <Input
                placeholder="Enter a name for your app"
                maxLength={100}
                {...register('name', {
                  required: 'Name is required',
                  maxLength: { value: 100, message: 'Name can be max 100 characters' },
                })}
              />
              {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="description" fontSize="sm">
                Description
              </FormLabel>
              <Textarea
                placeholder="Enter a description for your app"
                maxLength={1000}
                {...register('description', {
                  maxLength: { value: 1000, message: 'Description can be max 1000 characters' },
                })}
              />
              {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
            </FormControl>

            <Button rightIcon={<MdChevronRight />} type="submit" mt={4}>
              Continue
            </Button>
          </VStack>
        </form>
      </SoftCard>
    </AuthenticatedLayout>
  );
};
