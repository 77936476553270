import { Text, Flex, Switch, Link, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { SoftCard } from '../../components/generic/SoftCard';
import { useAppsContext } from '../../providers';
import { useAppActions } from '../../hooks';
import { App } from '../../types';

const formFields = ['isPublished'] as const;
type ShareFormFields = Pick<App, (typeof formFields)[number]>;

export const SharePage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { updateApp } = useAppActions();
  const { id } = useParams();

  const { handleSubmit, watch, control } = useForm<ShareFormFields>(
    selectedApp ? { values: _.pick(selectedApp, formFields) } : undefined
  );

  if (!id) return null;

  const onUpdateApp = (data: ShareFormFields) => updateApp({ ...data, id });
  const isPublished = watch('isPublished');

  if (!selectedApp) return null;

  return (
    <Flex flexDirection="column" gap={4}>
      <SoftCard title="Publish">
        <form onSubmit={handleSubmit(onUpdateApp)}>
          <Flex>
            <Flex flexGrow={1}>
              <Text mb="2">Enabling this toggle will publish your app and enable anyone to use it.</Text>
            </Flex>
            <Flex>
              <Controller
                control={control}
                name="isPublished"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Switch
                    id="isPublished"
                    size="lg"
                    name={name}
                    mb={4}
                    onBlur={onBlur}
                    onChange={async (event) => {
                      onChange(event.target.checked);
                      await handleSubmit(onUpdateApp)();
                    }}
                    isChecked={value}
                    ref={ref}
                  />
                )}
              />
            </Flex>
          </Flex>
        </form>
      </SoftCard>
      {isPublished && (
        <Flex flexDirection="column" gap={4}>
          <SoftCard title="Link">
            <Text>
              Your app is published and can viewed by visiting this link:{' '}
              <Link
                as={RouterLink}
                to={`${window.location.origin}/run/${selectedApp.slug}`}
              >{`${window.location.origin}/run/${selectedApp.slug}`}</Link>{' '}
            </Text>
          </SoftCard>
          <SoftCard title="Embed">
            <Box>
              <Text>
                Add this code to your site to display the app
                <pre>
                  {`
<iframe
  title="Clevis App"
  src="${`${window.location.origin}/run/${selectedApp.slug}`}"
  frameborder="0"
  border="0"
  cellspacing="0"
  width="100%"
  height="100%"
></iframe>
                  `}
                </pre>
              </Text>
            </Box>
          </SoftCard>
        </Flex>
      )}
    </Flex>
  );
};
