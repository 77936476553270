import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { App, User } from '../../types';
import { useNavigate } from 'react-router-dom';

export const InactiveAppDialog = ({
  app,
  user,
  isOpen,
  onClose,
}: {
  app: App;
  user: User;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const body =
    app.ownerId === user.id
      ? 'This app is not available because you do not have an active subscription. Press the button below to subscribe and gain access to your app.'
      : `This app is not available because the owner does not have an active subscription. Contact the app owner ${app.owner.email} to re-activate their subscription and gain access to the app.`;
  const cancelRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const onSubscribe = () => {
    navigate('/onboarding');
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Not Available
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              {app.ownerId === user.id && (
                <Button onClick={onSubscribe} mr={4}>
                  Subscribe
                </Button>
              )}
              <Button onClick={onClose}>Close</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
