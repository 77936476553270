import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

import { SoftCard } from '../../components/generic/SoftCard';
import { useAuthContext } from '../../providers';

export const ProfileSettingsPage: React.FC = () => {
  const { user } = useAuthContext();

  return (
    <SoftCard title="Personal information">
      {user && (
        <VStack spacing={4} alignItems="flex-start">
          <Box>
            <Text fontWeight={700}>Name</Text>
            <Text size="md">
              {user.firstName} {user.lastName}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={700}>Email</Text>
            <Text noOfLines={2}>{user.email}</Text>
          </Box>
        </VStack>
      )}
    </SoftCard>
  );
};
