import { useEffect } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Crisp } from 'crisp-sdk-web';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { theme } from '@packages/theme';
import {
  AppearancePage,
  AppPage,
  CreateAppPage,
  ListAppsPage,
  AppSettingsPage,
  SignInPage,
  AppUsersPage,
  AcceptAppInvitationPage,
  NotFoundPage,
  SignUpPage,
  SubscribePage,
  EditorPage,
  ListRunsPage,
  ResetPasswordPage,
  VerifyResetPasswordPage,
  ConfirmAccountPage,
  ErrorPage,
  ApiDocsPage,
  SharePage,
  SchedulePage,
  PaymentsPage,
  ProfileSettingsPage,
  BillingSettingsPage,
  PaymentsSettingsPage,
  SecretsPage,
  TutorialsPage,
  GenerateAppPage,
  FilesPage,
} from './pages';
import { AppsProvider, LoadingProvider, AuthProvider, AppConfigProvider, useAppConfigContext } from './providers';
import { AuthenticatedRoute, SelectedAppRoute, SettingsRoute, SignInRoute } from './routing';
import { SubscriptionRoute } from './routing/SubscriptionRoute';

const ConfigureAmplify = () => {
  const { config } = useAppConfigContext();

  Amplify.configure({
    Auth: {
      userPoolId: config.awsCognitoUserpoolId,
      identityPoolId: config.awsCognitoIdentityPoolId,
      region: config.awsRegion,
      userPoolWebClientId: config.awsCognitoAppClientId,
      oauth: {
        domain: config.awsCognitoLoginDomain,
        redirectSignIn: `${window.location.protocol}//${window.location.host}`,
        redirectSignOut: `${window.location.protocol}//${window.location.host}`,
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: config.awsS3AssetsBucket,
        region: config.awsRegion,
      },
    },
  });

  return null;
};

export const App = () => {
  useEffect(() => {
    Crisp.configure('5000b1ee-e5ca-4341-9a11-aaf24a89f3c6');

    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      Hotjar.init(3680680, 6, {});
    }

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }
  }, []);

  return (
    <Router>
      <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-right', variant: 'subtle' } }}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <LoadingProvider>
            <AppConfigProvider>
              <ConfigureAmplify />
              <AuthProvider>
                <AppsProvider>
                  <ColorModeScript initialColorMode={theme.config.initialColorMode} type="localStorage" />

                  <Routes>
                    <Route path="/apps" element={<AuthenticatedRoute />}>
                      <Route path="*" element={<SubscriptionRoute />}>
                        <Route index element={<ListAppsPage />} />
                        <Route path="create" element={<CreateAppPage />} />
                        <Route path="generate" element={<GenerateAppPage />} />
                        <Route path=":id" element={<SelectedAppRoute />}>
                          <Route path="appearance" element={<AppearancePage />} />
                          <Route path="files" element={<FilesPage />} />
                          <Route path="editor" element={<EditorPage />} />
                          <Route path="api" element={<ApiDocsPage />} />
                          <Route path="runs" element={<ListRunsPage />} />
                          <Route path="users" element={<AppUsersPage />} />
                          <Route path="secrets" element={<SecretsPage />} />
                          <Route path="schedule" element={<SchedulePage />} />
                          <Route path="share" element={<SharePage />} />
                          <Route path="payments" element={<PaymentsPage />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Route>
                      </Route>
                    </Route>

                    <Route path="/settings" element={<AuthenticatedRoute />}>
                      <Route path="*" element={<SettingsRoute />}>
                        <Route path="profile" element={<ProfileSettingsPage />} />
                        <Route path="billing" element={<BillingSettingsPage />} />
                        <Route path="payments" element={<PaymentsSettingsPage />} />
                        <Route path="app">
                          <Route path="settings" element={<AppSettingsPage />} />
                          <Route path="users" element={<AppUsersPage />} />
                        </Route>
                        <Route index element={<Navigate to="/settings/profile" />} />
                      </Route>
                    </Route>

                    <Route path="/invitations" element={<AuthenticatedRoute />}>
                      <Route path=":id" element={<AcceptAppInvitationPage />} />
                    </Route>

                    <Route path="/onboarding" element={<AuthenticatedRoute />}>
                      <Route index element={<SubscribePage />} />
                    </Route>

                    <Route path="/onboarding/:status" element={<AuthenticatedRoute />}>
                      <Route index element={<SubscribePage />} />
                    </Route>

                    <Route path="/sign-in" element={<SignInRoute />}>
                      <Route index element={<SignInPage />} />
                    </Route>

                    <Route path="/sign-up" element={<SignInRoute />}>
                      <Route index element={<SignUpPage />} />
                    </Route>

                    <Route path="/sign-up/confirm" element={<SignInRoute />}>
                      <Route index element={<ConfirmAccountPage />} />
                    </Route>

                    <Route path="/reset-password" element={<SignInRoute />}>
                      <Route index element={<ResetPasswordPage />} />
                    </Route>

                    <Route path="/reset-password/verify" element={<SignInRoute />}>
                      <Route index element={<VerifyResetPasswordPage />} />
                    </Route>

                    <Route path="/run/:slug" element={<AppPage />} />

                    <Route path="/tutorials" element={<TutorialsPage />} />

                    <Route path="/" element={<Navigate to="/apps" />} />

                    <Route path="/*" element={<NotFoundPage />} />
                  </Routes>
                </AppsProvider>
              </AuthProvider>
            </AppConfigProvider>
          </LoadingProvider>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </Router>
  );
};
