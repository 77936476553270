import React from 'react';
import { Heading, Box, Flex, Text } from '@chakra-ui/react';

import { SoftCard } from '../../components/generic/SoftCard';
import { ConfirmButton } from '../../components/generic/ConfirmButton';
import { useAppActions } from '../../hooks/app-actions';
import { useAppsContext } from '../../providers';
import { Entity, Operation } from '../../hooks/roles';
import { PrivilegeContainer } from '../../components/generic/PrivilegeContainer';

export const AppSettingsPage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { deleteApp } = useAppActions();
  if (!selectedApp) return null;

  const onDeleteApp = () => deleteApp(selectedApp?.id);

  return (
    <PrivilegeContainer entity={Entity.APPS} operation={Operation.DELETE}>
      <Box>
        <SoftCard>
          <Flex justifyContent="space-between" gap={2} alignItems="center">
            <Box>
              <Heading as="h3" size="sm" mb={2}>
                Delete app
              </Heading>
              <Text>Once you delete your app, there is no going back. Please be certain.</Text>
            </Box>

            <ConfirmButton
              onClick={onDeleteApp}
              title="Delete"
              body="Are you sure you want to delete this app?"
              colorScheme="red"
            />
          </Flex>
        </SoftCard>
      </Box>
    </PrivilegeContainer>
  );
};
