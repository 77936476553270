import tinycolor from 'tinycolor2';

export const getBrandButtonProps = (brandColor: string | null) => {
  const negotiatedBrandColor = brandColor?.includes('gradient') ? brandColor.split(',')[1]?.trim() : brandColor;
  const activeStyle = {
    background: negotiatedBrandColor ? tinycolor(negotiatedBrandColor).darken(10).toHexString() : undefined,
  };
  return brandColor
    ? {
        background: brandColor, // active, focus, hover becomes black
        _hover: activeStyle,
        _active: activeStyle,
        _focus: activeStyle,
      }
    : {};
};
