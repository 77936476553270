import { ChatGPTRole, ChatGptModel, StepType, TextInputType } from '@packages/clevis';
import { Template } from '../types';

export const blogPostGenerator: Template = {
  title: 'Blog Post Generator',
  description:
    'This app will generate a blog post based on a subject that the user enters by using ChatGPT for generating text and DALL-E for generating a cover image.',
  appConfig: {
    steps: [
      {
        id: 'subject_input',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter a subject for your blog post',
          inputType: TextInputType.INPUT,
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.SYSTEM,
              content:
                'You are a helpful assistant that will generate a blog post with 500 words on the subjects I tell you.',
            },
            {
              role: ChatGPTRole.USER,
              content: 'Generate a blog post on the subject {{ steps.subject_input.output }}',
            },
          ],
        },
      },
      {
        id: 'image',
        type: StepType.DALL_E,
        config: {
          size: '1024x1024',
          number: 1,
          prompt: 'Create a picture with the subject {{ steps.subject_input.output }}',
          style: 'natural',
        },
      },
      {
        id: 'format_post',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '<image src="{{ steps.image.output.images[0].url }}" /> \n{{ steps.gpt.output }}',
        },
      },
    ],
  },
};
