import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { StepConfig, TextInputStepTypeConfig, TextInputType } from '@packages/clevis';
import { UseFormRegister, useFormContext } from 'react-hook-form';
import { RunAppInput } from '../AppRunner';

export const TextInput = ({
  step,
  register,
  disabled = false,
}: {
  step: StepConfig<TextInputStepTypeConfig>;
  register: UseFormRegister<RunAppInput>;
  disabled?: boolean;
}) => {
  const {
    formState: { errors },
  } = useFormContext<RunAppInput>();
  const { title, inputType, helperText, value } = step.config as TextInputStepTypeConfig;
  const InputComponent = inputType === TextInputType.INPUT ? Input : Textarea;

  return (
    <FormControl isRequired isInvalid={!!(errors && errors[step.id])}>
      <FormLabel>{title}</FormLabel>
      <InputComponent disabled={disabled} {...register(step.id, { required: 'This field is required', value })} />
      {errors && errors[step.id]?.message && <FormErrorMessage>{errors[step.id]?.message}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
