import React, { useState } from 'react';
import { Button, FormControl, FormErrorMessage, Textarea, VStack, Text, FormHelperText } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { MdChevronRight } from 'react-icons/md';
import { useApiActions } from '../../hooks/api-actions';
import { AppSpinner } from './AppSpinner';
import { App } from '../../types';
import { useAppsContext } from '../../providers';
import { useNavigate } from 'react-router-dom';

type FormValues = {
  prompt: string;
};

type AppGeneratorProps = {
  appId?: string;
  onComplete?: () => void;
};

export const AppGenerator: React.FC<AppGeneratorProps> = ({ appId, onComplete }: AppGeneratorProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { executeApiAction } = useApiActions();
  const { setSelectedApp, setApps, apps } = useAppsContext();
  const navigate = useNavigate();

  const generateApp = ({ prompt }: FormValues) =>
    executeApiAction({
      timeout: 29500,
      action: ({ client }) => {
        setIsLoading(true);
        return client
          .post('apps/generate', {
            json: {
              prompt,
              appId,
            },
          })
          .json();
      },
      onSuccess: ({ app }: { app: App }) => {
        setIsLoading(false);
        setSelectedApp(app);
        if (onComplete) onComplete();
        if (appId) {
          setApps(apps.map((p) => (p.id === app.id ? app : p)));
        } else {
          setApps([...apps, app]);
          navigate(`/apps/${app.id}/editor`);
        }
      },
      onError: (_) => setIsLoading(false),
      successMessage: `App ${appId ? 'updated' : 'created'}`,
      errorMessage: 'Failed to generate app, try again',
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <Text mb="4">Describe what you want your app to do and let our AI assistant take care of creating it.</Text>
      {isLoading && <AppSpinner />}
      <form hidden={isLoading} onSubmit={handleSubmit(generateApp)}>
        <VStack spacing={4} alignItems="flex-start">
          <FormControl>
            <Textarea
              rows={4}
              placeholder='For example: "Create a business plan", "Create a recipe based on a list of ingredients" or "Give outfit suggestions based on the occasion"'
              maxLength={1000}
              {...register('prompt', {
                maxLength: { value: 1000, message: 'Max 1000 characters' },
              })}
            />
            {errors.prompt && <FormErrorMessage>{errors.prompt.message}</FormErrorMessage>}
            {appId && <FormHelperText>Note: This will overwrite any existing steps</FormHelperText>}
          </FormControl>

          <Button rightIcon={<MdChevronRight />} type="submit" mt={4}>
            {appId ? 'Generate' : 'Create'}
          </Button>
        </VStack>
      </form>
    </>
  );
};
