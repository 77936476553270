export type AppContext = {
  secrets: Record<string, string>;
};

type PrimitiveTypes = string | number | boolean;

export type RunInput = Record<string, PrimitiveTypes>;

export enum StepType {
  TEXT_INPUT = 'TEXT_INPUT',
  SELECT_INPUT = 'SELECT_INPUT',
  GEOLOCATION_INPUT = 'GEOLOCATION_INPUT',
  CHATGPT = 'CHATGPT',
  DISPLAY_OUTPUT = 'DISPLAY_OUTPUT',
  HTTP_REQUEST = 'HTTP_REQUEST',
  DALL_E = 'DALL_E',
  SEND_EMAIL = 'SEND_EMAIL',
}

export enum StepCategory {
  INPUT = 'INPUT',
  PROCESSING = 'PROCESSING',
  OUTPUT = 'OUTPUT',
}

export enum RunStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INPUT_REQUIRED = 'INPUT_REQUIRED',
}

export enum StepStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum TextInputType {
  INPUT = 'INPUT',
  TEXT_AREA = 'TEXT_AREA',
}

export type TextInputStepTypeConfig = {
  title: string;
  inputType?: TextInputType;
  helperText?: string;
  value?: string;
};

export type SelectOption = {
  text: string;
  value: string;
};

export type RunErrorResult = {
  message: string;
  name: string;
  details: string | null;
  stepId: string | null;
  debug: string | null;
};

export type RunAppResult = {
  run: {
    id: string;
    status: string;
    output: string;
    error: RunErrorResult;
  };
};

export type SendEmailStepTypeConfig = {
  subject: string;
  content: string;
  addresses: string[];
};

export type SelectInputStepTypeConfig = {
  title: string;
  helperText?: string;
  options: SelectOption[];
};

export type GeolocationStepTypeConfig = Record<string, unknown>;

export enum ChatGptModel {
  GPT_4 = 'gpt-4',
  GPT_3_5_TURBO = 'gpt-3.5-turbo',
  GPT_3_5_TURBO_1106 = 'gpt-3.5-turbo-1106',
}

export enum DallEModel {
  DALL_E_3 = 'dall-e-3',
  DALL_E_2 = 'dall-e-2',
}

export enum DallEStyle {
  VIVID = 'vivid',
  NATURAL = 'natural',
}

export enum DallEQuality {
  STANDARD = 'standard',
  HD = 'hd',
}

export enum ChatGPTRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}

export type ChatGPTMessage = {
  content: string;
  role: ChatGPTRole;
};

export type ChatGPTStepTypeConfig = {
  model: ChatGptModel;
  messages: ChatGPTMessage[];
  maxTokens?: number;
  jsonSchema?: string;
};

export const CHATGPT_DEFAULT_MAX_TOKENS = 2048;

export type DallEStepTypeConfig = {
  model?: DallEModel;
  prompt: string;
  number: number;
  style?: DallEStyle;
  quality?: DallEQuality;
  size: `${number}x${number}`;
};

export type DisplayOutputStepTypeConfig = {
  output: string;
};

export type HttpMethods = 'POST' | 'GET' | 'PUT' | 'DELETE';

export type HttpHeader = {
  header: string;
  value: string;
};

export type HTTPRequestStepTypeConfig = {
  url: string;
  method: HttpMethods;
  headers?: HttpHeader[];
  body?: string;
  parseText?: boolean;
  querySelector?: string;
};

export type StepTypeConfig =
  | TextInputStepTypeConfig
  | ChatGPTStepTypeConfig
  | DisplayOutputStepTypeConfig
  | HTTPRequestStepTypeConfig
  | SelectInputStepTypeConfig
  | DallEStepTypeConfig
  | SendEmailStepTypeConfig
  | GeolocationStepTypeConfig
  | Record<string, never>;

export type StepConfig<T extends StepTypeConfig> = {
  id: string;
  title?: string;
  type: StepType;
  config: T;
};

export type AppConfig = {
  timeout?: number;
  steps: StepConfig<StepTypeConfig>[];
};

export type StepOutput = Record<string, unknown> | string | number | boolean | null;

type StepState = {
  status: StepStatus;
  output: StepOutput;
  startedAt: Date;
  consumedCredits: number;
  completedAt?: Date;
};

export type StepStateMap = {
  [stepId: string]: StepState;
};

export type StepStateCallbackMap = {
  [stepId: string]: StepState;
};

export type RunState = {
  input: RunInput;
  steps: StepStateMap;
  appSecrets: Record<string, string>;
  hostedSecrets: Record<string, string>;
  promptContext?: (prompt: string, maxTokens: number) => Promise<string | null>;
};

export type ValidationMessage = {
  stepId?: string;
  message: string;
};

export interface IStep<T extends StepTypeConfig> {
  id: string;
  type: StepType;
  title?: string;
  category: StepCategory;
  execute(state: RunState): Promise<StepOutput>;
  validate(config: StepTypeConfig): void;
  getConfig(state?: RunState): Promise<StepConfig<T>>;
}
