import React from 'react';
import { Flex } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Footer, MainPanel, MainContent } from '../components/layout';
import { TopBar } from '../components/sidebar/TopBar';
import { Logo } from '../components/sidebar/Logo';
import { PageHeading } from '../components/generic/PageHeading';

interface SidebarLayoutProps {
  title?: string;
  icon?: IconType;
  children: React.ReactNode;
}

export const AuthenticatedLayout: React.FC<SidebarLayoutProps> = ({ title, icon, children }) => {
  return (
    <Flex flexDir="column" minH="100vh">
      <Flex justifyContent="space-between">
        <Flex flexDirection="row" gap={4}>
          <Flex p={4} justifyContent="center" alignItems="center">
            <Logo />
          </Flex>
          <PageHeading title={title} icon={icon} />
        </Flex>
        <TopBar />
      </Flex>
      <MainPanel>
        <MainContent>{children}</MainContent>
        <Footer />
      </MainPanel>
    </Flex>
  );
};
