import { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Button,
  Select,
  Box,
  Flex,
  FormHelperText,
  Text,
  Switch,
  useDisclosure,
  Textarea,
  Link,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import {
  CHATGPT_DEFAULT_MAX_TOKENS,
  ChatGPTRole,
  ChatGPTStepTypeConfig,
  ChatGptModel,
  StepConfig,
} from '@packages/clevis';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';
import { TemplateArea } from './TemplateArea';

export const PromptChatGPTStep = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, control, watch } = useFormContext<StepConfig<ChatGPTStepTypeConfig>>();
  const { fields, append, remove } = useFieldArray<StepConfig<ChatGPTStepTypeConfig>>({
    control,
    rules: { minLength: 1 },
    name: 'config.messages',
  });

  const messages = watch('config.messages');

  useEffect(() => {
    if (!fields || fields.length === 0) append({ content: '', role: ChatGPTRole.USER });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" gap={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="title" fontSize="sm">
          Model
        </FormLabel>
        <Select
          {...register('config.model', { required: 'Select a model' })}
          fontSize="sm"
          placeholder="Select a model"
        >
          {Object.values(ChatGptModel).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="title" fontSize="sm">
          Prompt Messages
        </FormLabel>
        <FormHelperText>
          Use output values from previous steps by referencing them like this {`{{ steps.step_id.output }}`} or
          selecting them in the list below.
        </FormHelperText>

        {fields.map((_, index) => (
          <Flex key={index} flexDirection="column" my={4} gap={4} flexGrow="1">
            <FormControl key={index} isRequired>
              <FormLabel htmlFor="title" fontSize="sm">
                Role
              </FormLabel>
              <Select
                {...register(`config.messages.${index}.role` as const, { required: 'Select a role' })}
                fontSize="sm"
              >
                <option value={ChatGPTRole.SYSTEM}>System</option>
                <option value={ChatGPTRole.USER}>User</option>
                <option value={ChatGPTRole.ASSISTANT}>Assistant</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="title" fontSize="sm">
                Message
              </FormLabel>
              <TemplateArea
                name={`config.messages.${index}.content`}
                fontSize="sm"
                placeholder="Message"
                variableTypes={['variables', 'secrets', 'custom', 'contexts']}
                control={control}
              />
            </FormControl>
          </Flex>
        ))}
        {messages && (
          <Box>
            <Button
              onClick={() => append({ content: '', role: ChatGPTRole.USER })}
              iconSpacing={0}
              leftIcon={<MdAdd />}
            />
            <Button
              iconSpacing={0}
              leftIcon={<MdDelete />}
              ml="4"
              colorScheme="red"
              isDisabled={messages.length < 2}
              onClick={() => remove(messages.length - 1)}
            />
          </Box>
        )}
      </FormControl>
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text as="b">Show advanced</Text>
          <Switch
            onChange={(event) => {
              if (event.target.checked) {
                onOpen();
              } else {
                onClose();
              }
            }}
          />
        </Flex>

        {isOpen && (
          <Flex flexDirection="column" gap={4}>
            <FormControl>
              <FormLabel>JSON Schema</FormLabel>
              <Textarea
                {...register('config.jsonSchema')}
                rows={10}
                placeholder={JSON.stringify(
                  {
                    type: 'object',
                    properties: {
                      location: {
                        type: 'string',
                        description: 'The city and state, e.g. San Francisco, CA',
                      },
                      unit: { type: 'string', enum: ['celsius', 'fahrenheit'] },
                    },
                    required: ['location'],
                  },
                  null,
                  2
                )}
              />
              <FormHelperText>
                Provide a{' '}
                <Link href="https://json-schema.org/" isExternal>
                  JSON schema
                </Link>{' '}
                that you want your response to be returned as.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Max tokens</FormLabel>
              <Controller
                control={control}
                name="config.maxTokens"
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                  <>
                    {value || CHATGPT_DEFAULT_MAX_TOKENS}
                    <Slider
                      id="maxTokens"
                      size="lg"
                      name={name}
                      step={10}
                      min={10}
                      max={8192}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb boxSize={6} />
                    </Slider>
                  </>
                )}
              />

              <FormHelperText>
                Configure the max tokens that ChatGPT can process. Note that the number of credits spent for running
                this step will increase if you increase this value.
              </FormHelperText>
            </FormControl>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
