import { ChatGPTRole, ChatGptModel, StepType } from '@packages/clevis';
import { Template } from '../types';

export const aiWeatherFit: Template = {
  title: 'Weather Fit',
  description:
    'Recommends the user what to wear based on the current weather. Accesses the users geolocation, pulls weather data from a weather API and uses ChatGPT to generate a recommendation.',
  appConfig: {
    steps: [
      {
        id: 'location',
        type: StepType.GEOLOCATION_INPUT,
        config: {},
      },
      {
        id: 'http_request',
        type: StepType.HTTP_REQUEST,
        config: {
          url: 'https://api.open-meteo.com/v1/forecast?latitude={{steps.location.output.latitude}}&longitude={{steps.location.output.longitude}}&current_weather=true',
          body: '',
          method: 'GET',
          headers: [],
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.USER,
              content:
                'This is the following weather my city:\n\nTemperature: {{steps.http_request.output.current_weather.temperature}} degrees celsius\nWindspeed: {{steps.http_request.output.current_weather.windspeed}} km/h\n\nProvide a suggestion on how I should dress when going out today',
            },
          ],
        },
      },
      {
        id: 'display',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '{{steps.gpt.output}}',
        },
      },
    ],
  },
};
