import {
  Text,
  Heading,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Link,
  Flex,
  FormControl,
  FormHelperText,
  Button,
  Box,
  Switch,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { SoftCard } from '../../components/generic/SoftCard';
import { useAppsContext } from '../../providers';
import { App } from '../../types';
import { useAppActions } from '../../hooks';
import { PrivilegeContainer } from '../../components/generic/PrivilegeContainer';
import { Entity, Operation } from '../../hooks/roles';

const formFields = ['price', 'runsPerPurchase', 'paymentsEnabled'] as const;
type PaymentFormFields = Pick<App, (typeof formFields)[number]>;

export const PaymentsPage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { id } = useParams();
  const { updateApp } = useAppActions();

  const { register, handleSubmit, watch, control } = useForm<PaymentFormFields>(
    selectedApp
      ? {
          defaultValues: _.pick(selectedApp, formFields),
        }
      : undefined
  );

  const paymentsEnabled = watch('paymentsEnabled');

  const onUpdateApp = async (data: PaymentFormFields) => {
    if (!selectedApp) return;
    await updateApp({ ...data, id: selectedApp?.id });
  };

  if (!id) return null;
  if (!selectedApp) return null;

  return (
    <SoftCard>
      <Heading as="h2" size="md" mb={4}>
        Payments
      </Heading>
      {selectedApp.owner.stripeAccountChargesEnabled && (
        <form onSubmit={handleSubmit(onUpdateApp)}>
          <Flex gap={4} flexDirection="column">
            <Text>
              Enable payments to start charging users to use your app. Example: If you set a price of $10 and runs per
              purchase to 5, a user who pays $10 will be able to use your app 5 times.
            </Text>
            <Text fontSize="sm" fontStyle="italic">
              Note: Clevis charges a 10% administration fee for all app payments.
            </Text>

            <Flex>
              <Flex flexGrow={1}>
                <Text mb="2">
                  {paymentsEnabled ? 'Disable' : 'Enable'} this toggle and hit save to{' '}
                  {paymentsEnabled ? 'disable' : 'enable'} payments
                </Text>
              </Flex>
              <Flex>
                <Controller
                  control={control}
                  name="paymentsEnabled"
                  render={({ field: { onChange, onBlur, value, name, ref }, formState }) => (
                    <Switch
                      id="paymentsEnabled"
                      size="lg"
                      name={name}
                      mb={4}
                      onBlur={onBlur}
                      onChange={onChange}
                      isChecked={value}
                      ref={ref}
                    />
                  )}
                />
              </Flex>
            </Flex>

            <FormControl isDisabled={!paymentsEnabled} isRequired>
              <FormLabel htmlFor="price">Price</FormLabel>

              <NumberInput>
                <NumberInputField id="price" {...register('price', { valueAsNumber: true })} min={50} max={10000} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>Enter the price to run your app in USD cent, e.g. $9.99 = 999.</FormHelperText>
            </FormControl>

            <FormControl isDisabled={!paymentsEnabled} isRequired>
              <FormLabel htmlFor="runsPerPurchase">Runs per purchase</FormLabel>

              <NumberInput>
                <NumberInputField
                  id="runsPerPurchase"
                  {...register('runsPerPurchase', { valueAsNumber: true })}
                  min={0}
                  max={1000}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>
                Enter the number of app runs that a customer should receieve when paying for your app.
              </FormHelperText>
            </FormControl>
            <Box>
              <PrivilegeContainer entity={Entity.APPS} operation={Operation.UPDATE}>
                <Button type="submit">Save</Button>
              </PrivilegeContainer>
            </Box>
          </Flex>
        </form>
      )}
      {!selectedApp.owner.stripeAccountChargesEnabled && (
        <Flex flexDirection="column" gap={2}>
          <Text>
            Payments can be used to monetize your app once it's published. Users will be prompted to pay the amount you
            specify to run the app a set amount of times.
          </Text>
          <Text>
            To enable payments, first configure a payment account in your{' '}
            <Link as={RouterLink} to="/settings/payments">
              payment settings.
            </Link>
          </Text>
        </Flex>
      )}
    </SoftCard>
  );
};
