import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Tag,
  Text,
} from '@chakra-ui/react';
import { AppConfig, StepConfig, StepTypeConfig, getCost } from '@packages/clevis';
import { SoftCard } from '../generic/SoftCard';
import { useAppsContext } from '../../providers';
import { ConfirmButton } from '../generic/ConfirmButton';
import { useAppActions } from '../../hooks';
import templates from '../../templates';

type TemplateDrawerProps = { isOpen: boolean; onClose: () => void };

export const TemplateDrawer: React.FC<TemplateDrawerProps> = ({ isOpen, onClose }) => {
  const { selectedApp, setSelectedApp } = useAppsContext();
  const { updateApp } = useAppActions();

  const calculateCost = (steps: StepConfig<StepTypeConfig>[]) => {
    if (steps && steps.length > 0) {
      return getCost({ steps });
    }

    return 0;
  };

  const saveTemplate = async (template: AppConfig) => {
    if (!selectedApp) return;

    setSelectedApp({ ...selectedApp, appConfig: template });
    onClose();

    await updateApp({
      id: selectedApp.id,
      appConfig: template,
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <DrawerCloseButton />

        <DrawerHeader>Templates</DrawerHeader>
        <DrawerBody>
          <Flex flexDirection="column" gap={4}>
            {templates.map((template) => (
              <SoftCard key={template.title}>
                <Flex flexGrow={1} flexDirection="column">
                  <Heading size="md" as="h2">
                    {template.title}
                  </Heading>
                  <Text size="md" my={4}>
                    {template.description}
                  </Text>
                  <Flex mb={4} gap={2}>
                    <Tag>Steps: {template.appConfig.steps.length}</Tag>
                    <Tag>Cost per run: {calculateCost(template.appConfig.steps)} credits</Tag>
                  </Flex>
                </Flex>

                {selectedApp?.appConfig && selectedApp.appConfig.steps.length > 0 ? (
                  <ConfirmButton
                    onClick={() => saveTemplate(template.appConfig)}
                    title="Use template"
                    cta="Overwrite"
                    body="You already have an app configured, would you like to overwrite your app with this template?"
                  />
                ) : (
                  <Button mt={2} onClick={() => saveTemplate(template.appConfig)}>
                    Use template
                  </Button>
                )}
              </SoftCard>
            ))}
          </Flex>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};
