import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { App } from '../types';
import { useAuthContext } from './authentication-provider';
import { ListAppsResponse, useAsyncError } from '../hooks';
import { useApiActions } from '../hooks/api-actions';
import { LoadingPage } from '../pages';

interface AppsProviderProps {
  apps: App[];
  selectedApp: App | null;
  setApps: (apps: App[]) => void;
  setSelectedApp: (app: App | null) => void;
  fetchApps: () => Promise<void>;
}

const AppsContext = createContext<AppsProviderProps | null>(null);

export const AppsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();
  const { executeApiAction } = useApiActions();
  const throwError = useAsyncError();

  const [loading, setLoading] = useState(true);
  const [apps, setApps] = useState<App[]>([]);
  const [selectedApp, setSelectedAppState] = useState<App | null>(null);

  const fetchApps = async () => {
    const res = await executeApiAction<App[]>({
      action: async ({ client }) => (await client.get('apps').json<ListAppsResponse>()).apps,
      onSuccess: () => {
        setLoading(false);
      },
      onError: (error: any) => {
        throwError(error);
      },
    });

    setApps(res || []);
    const selectedAppId = localStorage.getItem('selectedAppId');
    if (selectedAppId) {
      setSelectedApp((res || []).find((app) => app.id === selectedAppId) || null);
    }
  };

  useEffect(() => {
    if (user) {
      fetchApps();
    } else {
      setApps([]);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setSelectedApp = (app: App | null) => {
    if (app) {
      localStorage.setItem('selectedAppId', app.id);
      setSelectedAppState(app);
    } else {
      setSelectedAppState(null);
      localStorage.removeItem('selectedAppId');
    }
  };

  return (
    <AppsContext.Provider
      value={{
        apps,
        selectedApp,
        setApps,
        setSelectedApp,
        fetchApps,
      }}
    >
      {loading ? <LoadingPage /> : children}
    </AppsContext.Provider>
  );
};

export const useAppsContext = (): AppsProviderProps => {
  const context = useContext(AppsContext);

  if (!context) {
    throw new Error('Failed to load apps context. Make sure you are consuming the context within a provider block');
  }

  return context;
};
