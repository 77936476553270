import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { useAppsContext } from '../../providers';
import { SoftCard } from '../../components/generic/SoftCard';
import { ApiKeyEditor } from '../../components/apps/ApiKeyEditor';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
export const ApiDocsPage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const spec = {
    openapi: '3.0.3',
    info: {
      title: 'Clevis API',
      description:
        'The Clevis API can be used for triggering app runs and fetching the result from a run. Triggering a run will consume credits on your account. \n\n You will need an API key to use the Clevis API, it can be generated in your app settings. Attach the api key as a bearer token in the authorization header of your request. e.g. `Authorization: Bearer <api-key>`',
      version: '1.0.0',
    },
    servers: [
      {
        url: 'https://api.clevis.app/external',
      },
      ...(process.env.REACT_APP_ENVIRONMENT === 'local'
        ? [
            {
              url: 'http://localhost:4000/external',
            },
          ]
        : []),
    ],
    paths: {
      '/apps/{appId}/runs': {
        post: {
          tags: ['runs'],
          summary: 'Create a new run',
          description: 'Creates a new run for your app',
          operationId: 'runAppById',
          parameters: [
            {
              name: 'appId',
              in: 'path',
              description: 'The ID of your app',
              required: true,
              schema: {
                type: 'string',
                default: selectedApp?.id,
              },
            },
          ],
          requestBody: {
            description: 'Send the input to your run in the input field with the id of the input step as the key',
            required: true,
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    input: {
                      type: 'object',
                      example: {
                        'step-id': 'Input to step',
                      },
                    },
                  },
                },
              },
            },
          },
          responses: {
            '200': {
              description: 'successful operation',
              content: {
                'application/json': {
                  schema: {
                    type: 'object',
                    properties: {
                      id: {
                        type: 'string',
                        format: 'uuid',
                        example: '124c02d8-03eb-4da1-b3e8-6a71fe1786e2',
                      },
                    },
                  },
                },
              },
            },
            '401': {
              description: 'Invalid api key',
            },
            '404': {
              description: 'App not found',
            },
          },
        },
      },
      '/apps/{appId}/runs/{runId}': {
        get: {
          tags: ['runs'],
          summary: 'Get the status of a run',
          description:
            'Endpoint for fetching the current status of a run. Can be polled to get the current status of the run and the result',
          operationId: 'getRunById',
          parameters: [
            {
              name: 'appId',
              in: 'path',
              description: 'The ID of your app',
              required: true,
              schema: {
                type: 'string',
                default: selectedApp?.id,
              },
            },
            {
              name: 'runId',
              in: 'path',
              description: 'The ID of the run returned when creating a new run∫',
              required: true,
              schema: {
                type: 'string',
              },
            },
          ],
          responses: {
            '200': {
              description: 'successful operation',
              content: {
                'application/json': {
                  schema: {
                    type: 'object',
                    properties: {
                      id: {
                        type: 'string',
                        example: '242405e9-76fb-480e-a6e3-e3f49ab9e24c',
                      },
                      appId: {
                        type: 'string',
                        example: '3dc6e93e-c9ce-43cb-b613-9be147e2466c',
                      },
                      status: {
                        type: 'string',
                        enum: ['IN_PROGRESS', 'COMPLETED', 'FAILED'],
                        example: 'IN_PROGRESS',
                      },
                      output: {
                        type: 'string',
                        example: 'Here is your response!',
                      },
                      error: {
                        type: 'object',
                        properties: {
                          message: {
                            type: 'string',
                            example: 'Failed to render template',
                          },
                          name: {
                            type: 'string',
                            example: 'RENDER_ERROR',
                          },
                          details: {
                            type: 'string',
                            example: 'undefined variable: steps.gpt.outputs, line:3, col:4, line:3, col:1',
                          },
                          stepId: {
                            type: 'string',
                            example: 'output',
                          },
                        },
                      },
                      consumedCredits: {
                        type: 'number',
                        format: 'int32',
                      },
                      state: {
                        type: 'object',
                        additionalProperties: {
                          type: 'object',
                          properties: {
                            output: {
                              type: 'string',
                            },
                            status: {
                              type: 'string',
                              enum: ['IN_PROGRESS', 'COMPLETED', 'FAILED'],
                              example: 'IN_PROGRESS',
                            },
                            startedAt: {
                              type: 'string',
                              format: 'date-time',
                            },
                            completedAt: {
                              type: 'string',
                              format: 'date-time',
                            },
                          },
                        },
                      },
                      createdAt: {
                        type: 'string',
                        format: 'date-time',
                      },
                      updatedAt: {
                        type: 'string',
                        format: 'date-time',
                      },
                    },
                  },
                },
              },
            },
          },
          '401': {
            description: 'Invalid api key',
          },
          '404': {
            description: 'App not found or run not found',
          },
        },
      },
    },
    security: [
      {
        ApiKeyAuth: [],
      },
    ],
    components: {
      securitySchemes: {
        ApiKeyAuth: {
          type: 'http',
          scheme: 'bearer',
          bearerFormat: 'API Key',
        },
      },
    },
  };

  return (
    <Flex flexDirection="column" gap={4}>
      <Box>
        <SoftCard title="API Key">
          <Box mb={4}>
            <Text>The API key can be used to call the Clevis API and trigger runs of your app</Text>
          </Box>
          <ApiKeyEditor />
        </SoftCard>
      </Box>

      <Box>
        <SoftCard p={2}>
          <Heading fontSize="lg" as="h2" p={4} mb={-8}>
            Documentation
          </Heading>
          <SwaggerUI spec={spec} />
        </SoftCard>
      </Box>
    </Flex>
  );
};
