import React from 'react';
import { StepConfig, TextInputStepTypeConfig, TextInputType } from '@packages/clevis';
import { FormControl, FormLabel, Input, FormHelperText, Flex, Select } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { TemplateArea } from './TemplateArea';

export const TextInputStep = () => {
  const { register, control } = useFormContext<StepConfig<TextInputStepTypeConfig>>();

  return (
    <Flex flexDirection="column" gap={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="title" fontSize="sm">
          Title
        </FormLabel>
        <Input
          id="title"
          type="text"
          fontSize="sm"
          {...register('config.title', { required: 'Enter a valid title' })}
        />
        <FormHelperText>The title appears above the input field in your app</FormHelperText>
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="inputType" fontSize="sm">
          Input type
        </FormLabel>
        <Select
          id="inputType"
          fontSize="sm"
          {...register('config.inputType', { required: 'Select an input type' })}
          defaultValue={TextInputType.INPUT}
        >
          <option value={TextInputType.INPUT}>Input</option>
          <option value={TextInputType.TEXT_AREA}>Text area</option>
        </Select>
        <FormHelperText>Select how the input should be displayed</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="helperText" fontSize="sm">
          Helper text
        </FormLabel>
        <Input id="helperText" type="helperText" fontSize="sm" {...register('config.helperText')} />
        <FormHelperText>Text displayed under the input with additional information</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="value" fontSize="sm">
          Value
        </FormLabel>
        <TemplateArea
          id="value"
          name="config.value"
          fontSize="sm"
          control={control}
          singleLine
          variableTypes={['variables']}
        />
        <FormHelperText>Set an initial value for the input field that the user can edit</FormHelperText>
      </FormControl>
    </Flex>
  );
};
