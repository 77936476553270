import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from '../providers';

export const SignInRoute = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user) {
      const continueParam = searchParams.get('continue');

      if (continueParam) {
        navigate(continueParam);
      } else {
        navigate('/apps');
      }
    }
  }, [user, navigate, searchParams]);

  return <Outlet />;
};
