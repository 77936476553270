import {
  ChatGPTStepTypeConfig,
  DallEStepTypeConfig,
  DisplayOutputStepTypeConfig,
  HTTPRequestStepTypeConfig,
  StepConfig,
  StepType,
  StepTypeConfig,
} from '@packages/clevis';

export function removeEmptyFields<T extends Record<string, unknown>>(data: Record<string, unknown>) {
  const result = {} as T;
  Object.keys(data).forEach((key) => {
    if (data[key] !== '' && data[key] !== null) {
      result[key as keyof T] = data[key] as T[keyof T];
    }
  });
  return result;
}

export const getWarning = (step: StepConfig<StepTypeConfig>, allSteps: string[]): string | null => {
  const getVariables = (templateString: string): string[] =>
    templateString
      .match(/{{(\s*step.*?)}}/g)
      ?.map(
        (variable) =>
          variable.replace('steps.', '').replace('.output', '').replace('{{', '').replace('}}', '').trim().split('.')[0]
      ) || [];

  const findMissingVariables = (variables: string[]) =>
    variables.filter((variable) => {
      if (variable === 'secrets' || variable.includes('|')) return false;
      return !allSteps.includes(variable);
    });

  const variables: string[] = [];

  if (step.type === StepType.CHATGPT) {
    const chatGptStep = step.config as ChatGPTStepTypeConfig;
    chatGptStep.messages.forEach((message) => {
      const variablesInMessage = getVariables(message.content);
      variables.push(...variablesInMessage);
    });
  }

  if (step.type === StepType.HTTP_REQUEST) {
    const httpRequestStep = step.config as HTTPRequestStepTypeConfig;
    if (httpRequestStep.body) variables.push(...getVariables(httpRequestStep.body));
    variables.push(...getVariables(httpRequestStep.url));
    httpRequestStep.headers?.forEach((header) => {
      variables.push(...getVariables(header.value));
    });
  }

  if (step.type === StepType.DALL_E) {
    const dallEStep = step.config as DallEStepTypeConfig;
    variables.push(...getVariables(dallEStep.prompt));
  }

  if (step.type === StepType.DISPLAY_OUTPUT) {
    const displayOutputStep = step.config as DisplayOutputStepTypeConfig;
    variables.push(...getVariables(displayOutputStep.output));
  }

  const missingVariables = findMissingVariables(variables);

  if (missingVariables.length) {
    return `Invalid step reference${missingVariables.length > 1 ? 's' : ''}: ${missingVariables.join(', ')}`;
  }

  return null;
};
