import joi from 'joi';
import { ChatGPTRole, ChatGptModel, DallEModel, DallEQuality, DallEStyle, StepType, TextInputType } from './types';

export const dallESchema = joi
  .object({
    prompt: joi.string().min(1).required(),
    number: joi.number().min(1).max(10).required(),
    size: joi.string().required(),
    style: joi
      .string()
      .valid(...Object.values(DallEStyle))
      .when('model', { is: DallEModel.DALL_E_3, then: joi.required(), otherwise: joi.optional() }),
    model: joi.string().valid(...Object.values(DallEModel)),
    quality: joi
      .string()
      .valid(...Object.values(DallEQuality))
      .when('model', { is: DallEModel.DALL_E_3, then: joi.required(), otherwise: joi.optional() }),
  })
  .required();

export const textInputSchema = joi
  .object({
    title: joi.string().min(1).required(),
    inputType: joi.string().valid(...Object.keys(TextInputType)),
    helperText: joi.string().optional().allow('').allow(null),
    value: joi.string().optional().allow(''),
  })
  .required();

export const selectInputSchema = joi
  .object({
    title: joi.string().required(),
    helperText: joi.string().optional().allow('').allow(null),
    options: joi
      .array()
      .items(
        joi.object({
          text: joi.string().required(),
          value: joi.string().required(),
        })
      )
      .min(1)
      .required(),
  })
  .required();

export const chatGptSchema = joi
  .object({
    model: joi
      .string()
      .required()
      .valid(...Object.values(ChatGptModel)),
    messages: joi
      .array()
      .items(
        joi.object({
          content: joi.string().required(),
          role: joi.valid(ChatGPTRole.ASSISTANT, ChatGPTRole.SYSTEM, ChatGPTRole.USER).required(),
        })
      )
      .max(10)
      .required(),
    apiKey: joi.string().optional(),
    maxTokens: joi.number().optional(),
    jsonSchema: joi
      .string()
      .optional()
      .allow('')
      .allow(null)
      .custom((value, helpers) => {
        if (!value) return value;
        try {
          JSON.parse(value);
          return value;
        } catch (err) {
          return helpers.error('The schema is not valid JSON');
        }
      }),
  })
  .required();

export const httpRequestSchema = joi
  .object({
    url: joi.string().required(),
    method: joi.string().required().valid('POST', 'GET', 'PUT', 'DELETE'),
    headers: joi.array().items(joi.object({ header: joi.string().required(), value: joi.string().required() })),
    body: joi.string().optional().allow(''),
    parseText: joi.boolean().optional(),
    querySelector: joi.string().optional().allow(''),
  })
  .required();

export const displayOutputSchema = joi.object({ output: joi.string().required() }).required();

export const sendEmailSchema = joi
  .object({
    addresses: joi.array().items(joi.string().required()).max(5).required(),
    subject: joi.string().required(),
    content: joi.string().required(),
  })
  .required();

const geolocationScehma = joi.object().keys({});

export const stepSchema = joi.object({
  id: joi
    .string()
    .required()
    .min(1)
    .max(50)
    .regex(/^[a-zA-Z0-9-_]+$/),
  title: joi.string().optional().max(50),
  type: joi.string().valid(...Object.keys(StepType)),
  config: joi
    .alternatives()
    .conditional('type', [
      { is: StepType.TEXT_INPUT, then: textInputSchema.required() },
      { is: StepType.CHATGPT, then: chatGptSchema.required() },
      { is: StepType.HTTP_REQUEST, then: httpRequestSchema.required() },
      { is: StepType.DISPLAY_OUTPUT, then: displayOutputSchema.required() },
      { is: StepType.SELECT_INPUT, then: selectInputSchema.required() },
      { is: StepType.DALL_E, then: dallESchema.required() },
      { is: StepType.GEOLOCATION_INPUT, then: geolocationScehma.required() },
      { is: StepType.SEND_EMAIL, then: sendEmailSchema.required() },
    ])
    .required(),
});

export const appSchema = joi
  .object({
    steps: joi
      .array()
      .items(stepSchema)
      .unique((a, b) => a.id === b.id)
      .required()
      .min(1),
  })
  .messages({
    'array.unique': 'The step "{#value.id}" already exists',
  });
