import { defineStyleConfig, baseTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableAnatomy as tableParts, cardAnatomy as cardParts, modalAnatomy as modalParts, menuAnatomy as menuParts, drawerAnatomy as drawerParts, } from '@chakra-ui/anatomy';
const tableHelpers = createMultiStyleConfigHelpers(tableParts.keys);
const cardHelpers = createMultiStyleConfigHelpers(cardParts.keys);
const modalHelpers = createMultiStyleConfigHelpers(modalParts.keys);
const menuHelpers = createMultiStyleConfigHelpers(menuParts.keys);
const drawerHelpers = createMultiStyleConfigHelpers(drawerParts.keys);
const Button = defineStyleConfig({
    defaultProps: {
        variant: 'gradient',
    },
    variants: {
        warning: {
            background: 'none',
            _hover: {
                background: 'none',
                backgroundPosition: '60% center',
                bgGradient: `linear(to-r, #F7971E, #FFD200)`,
            },
            backgroundSize: '180%',
            transition: 'all .3s ease-in-out',
            border: 'none',
            color: 'white',
            bgGradient: `linear(to-r, #F7971E, #FFD200)`,
        },
        gradient: {
            background: 'none',
            _hover: {
                _disabled: {
                    bgGradient: `linear(to-r, gradient.start, brand.400)`,
                },
                background: 'none',
                backgroundPosition: '60% center',
                bgGradient: `linear(to-r, gradient.start, brand.400)`,
            },
            backgroundSize: '180%',
            transition: 'all .3s ease-in-out',
            border: 'none',
            color: 'white',
            bgGradient: `linear(to-r, gradient.start, brand.400)`,
        },
    },
});
const Link = defineStyleConfig({
    baseStyle: {
        color: 'brand.400',
    },
});
const Badge = defineStyleConfig({
    baseStyle: {
        borderRadius: 4,
    },
});
const Table = tableHelpers.defineMultiStyleConfig({
    baseStyle: {
        th: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: 'gray.900',
            background: 'gray.900',
            textTransform: 'capitalize',
        },
        tr: {
            borderBottomWidth: 1,
            borderBottomColor: 'gray.700',
        },
    },
    defaultProps: {
        variant: 'simple',
        size: 'md',
        colorScheme: 'gray',
    },
});
const Card = cardHelpers.defineMultiStyleConfig({
    baseStyle: {
        container: {
            bg: 'gray.950',
            _hover: {
                bg: 'gray.900',
            },
        },
    },
});
const Modal = modalHelpers.defineMultiStyleConfig({
    baseStyle: {
        dialog: {
            bg: 'gray.950',
        },
    },
});
const Menu = menuHelpers.defineMultiStyleConfig({
    baseStyle: {
        list: {
            bg: 'gray.950',
        },
        item: {
            bg: 'gray.950',
            _hover: {
                bg: 'gray.900',
            },
        },
    },
});
const Drawer = drawerHelpers.defineMultiStyleConfig({
    baseStyle: {
        dialog: {
            bg: 'gray.950',
        },
    },
});
export const globalStyles = {
    colors: {
        brand: {
            50: '#ffe2f8',
            100: '#ffb2e2',
            200: '#ff80cd',
            300: '#fe4eb9',
            400: '#fd21a4',
            500: '#e40d8b',
            600: '#b2056c',
            700: '#80004d',
            800: '#4e002e',
            900: '#1e0011',
        },
        gray: {
            ...baseTheme.colors.gray,
            950: '#101118',
        },
        gradient: {
            start: '#fc4760',
            end: '#fd21a4',
        },
    },
    fonts: {
        body: 'system-ui, sans-serif',
        heading: 'system-ui, sans-serif',
        mono: 'Consolas, monospace',
    },
    components: {
        Button,
        Link,
        Table,
        Card,
        Modal,
        Menu,
        Drawer,
        Badge,
    },
    styles: {
        global: () => ({
            body: {
                bg: 'black',
            },
            'html, body, #root': {
                minHeight: '100vh',
            },
            '.shine-animation': {
                position: 'relative',
                overflow: 'hidden',
            },
            '.shine-animation::before': {
                content: "''",
                position: 'absolute',
                width: '100px',
                height: '100%',
                backgroundImage: 'linear-gradient(120deg, rgba(255,255,255,0) 30%, rgba(255,255,255,.8), rgba(255,255,255,0) 70%)',
                top: 0,
                left: '-100px',
                animation: 'shine 3s infinite linear',
            },
            '@keyframes shine': {
                '0%': { left: '-100px' },
                '20%': { left: '100%' },
                '100%': { left: '100%' },
            },
            '.swagger-ui': {
                filter: 'invert(88%) hue-rotate(180deg)',
            },
            '.swagger-ui .microlight': {
                filter: 'invert(100%) hue-rotate(180deg)',
            },
        }),
    },
};
