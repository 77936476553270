import { aiBartender } from './ai-bartender';
import { blogPostGenerator } from './blog-post-generator';
import { characterCreator } from './character-creator';
import { recipeCreator } from './recipe-creator';
import { motivationalAi } from './motivational-ai';
import { Template } from '../types';
import { aiWeatherFit } from './ai-weather-fit';
import { resumeSummarizer } from './resume-summarizer';
import { resumeParser } from './cv-parser';

const alphabeticalSort = (a: Template, b: Template) => {
  const nameA = a.title.toUpperCase();
  const nameB = b.title.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const templates: Template[] = [
  aiWeatherFit,
  aiBartender,
  blogPostGenerator,
  characterCreator,
  recipeCreator,
  motivationalAi,
  resumeSummarizer,
  resumeParser,
  // newsSummarizer,
  // travelPlanner
].sort(alphabeticalSort);

export default templates;
