import { Box, Text, Code } from '@chakra-ui/react';
import { SoftCard } from '../../components/generic/SoftCard';
import { SecretsEditor } from '../../components/apps/SecretsEditor';

export const SecretsPage: React.FC = () => {
  return (
    <SoftCard title="Secrets">
      <Text mb="4">Secrets are stored using secure encryption and can only be accessed by you and your app.</Text>
      <Text>
        Secrets can be referenced in your apps steps like this <Code>{'{{ secrets.MY_SECRET }}'}</Code>
      </Text>
      <Box mt={4}>
        <SecretsEditor />
      </Box>
    </SoftCard>
  );
};
