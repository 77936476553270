import { AppConfig } from '@packages/clevis';
import { Secret } from '../../../types';
import { StepTypeMetadataMap } from '../types';

export const getVariables = (config: AppConfig | null, beforeStepId: string) => {
  if (!config) return [];

  const { steps } = config;

  const beforeIndex = steps.findIndex((val) => val.id === beforeStepId);
  const validSteps = beforeIndex === -1 ? steps : steps.slice(0, beforeIndex);

  const outputs = validSteps.map((step) => {
    const meta = StepTypeMetadataMap[step.type];

    return {
      stepId: step.id,
      icon: meta.icon,
      outputs: meta.getOutputs(step.id).map((output) => ({ text: output.key, value: output.variable })),
    };
  });

  return outputs;
};

export const getSecrets = (secrets: Secret[]) => {
  return secrets.map((secret) => {
    return { text: secret.key, value: `secret.${secret.key}` };
  });
};
