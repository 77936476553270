import { Button } from '@chakra-ui/react';
import { useApiActions } from '../../hooks/api-actions';

type BuyButtonProps = {
  appId: string;
  runsPerPurchase?: number | null;
  price?: number | null;
};

export const BuyButton: React.FC<BuyButtonProps> = ({ appId, price, runsPerPurchase }) => {
  const { executeApiAction } = useApiActions();
  const buy = () =>
    executeApiAction({
      action: async ({ publicClient }) =>
        (await publicClient.post('checkout', { json: { appId } }).json<{ session: { url: string } }>()).session.url,
      onSuccess: (url: string) => window.location.replace(url),
      errorMessage: 'Failed to create checkout session',
    });

  if (!price || !runsPerPurchase) {
    return <Button isDisabled>Missing price settings</Button>;
  }

  return (
    <Button onClick={buy}>
      Buy {runsPerPurchase} {runsPerPurchase > 1 ? 'runs' : 'run'} (${price / 100})
    </Button>
  );
};
