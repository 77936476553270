import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppsContext, useAuthContext } from '../providers';
import { useEffect } from 'react';
import { PlanId } from '../types';

export const SubscriptionRoute = () => {
  const { selectedApp } = useAppsContext();
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Invitations are broken
    if (user && (!user.hasActiveSubscription || user.planId === PlanId.FREE)) {
      return navigate('/onboarding');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user, selectedApp]);

  return <Outlet />;
};
