import {
  Text,
  Heading,
  Icon,
  Badge,
  SimpleGrid,
  AvatarGroup,
  Avatar,
  Flex,
  MenuItem,
  Menu,
  MenuList,
  MenuButton,
  IconButton,
  LinkBox,
  LinkOverlay,
  Box,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdAdd, MdApps, MdCalendarMonth, MdCopyAll, MdMenu } from 'react-icons/md';
import { format } from 'date-fns';

import { AuthenticatedLayout } from '../layouts';
import { App } from '../types';
import { InactiveAppDialog } from '../components/apps/InactiveAppDialog';
import { SoftCard } from '../components/generic/SoftCard';
import { useAppsContext, useAuthContext } from '../providers';
import { useAppActions } from '../hooks';

const cardStyle = {
  minHeight: '180px',
  cursor: 'pointer',
  display: 'flex',
};

export const ListAppsPage: React.FC = () => {
  const navigate = useNavigate();
  const [inactiveAppId, setInactiveAppId] = useState<string | null>(null);
  const { user } = useAuthContext();
  const { apps, setSelectedApp } = useAppsContext();
  const { createApp } = useAppActions();

  const onDuplicate = async (app: App) => {
    await createApp({
      name: `${app.name} (copy)`,
      description: app.description,
      appConfig: app.appConfig || undefined,
    });
  };

  const AppCard = ({ app }: { app: App }) => (
    <LinkBox as={SoftCard} key={app.id} justifyContent="space-between" {...cardStyle}>
      <Flex flexDirection="column" gap={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Badge variant={app.owner.hasActiveSubscription ? 'solid' : 'subtle'}>
            {app.owner.hasActiveSubscription ? 'Active' : 'Inactive'}
          </Badge>
          <Box zIndex={2}>
            <Menu>
              <MenuButton as={IconButton} icon={<MdMenu />} size="xs" borderRadius={2} variant="outline">
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem icon={<MdCopyAll />} onClick={() => onDuplicate(app)}>
                  Duplicate
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>

        <Flex flexDirection="column" gap={1}>
          <LinkOverlay
            as={Link}
            to={`/apps/${app.id}/editor`}
            onClick={() => {
              app.owner.hasActiveSubscription ? setSelectedApp(app) : setInactiveAppId(app.id);
            }}
          >
            <Heading size="md">{app.name}</Heading>
          </LinkOverlay>
          <Text noOfLines={2}>{app.description}</Text>
        </Flex>
      </Flex>
      <Flex mt={8} justifyContent="space-between" alignItems="center">
        <AvatarGroup max={3} size="sm">
          {app.users?.map((user) => (
            <Avatar key={user.id} name={`${user.firstName} ${user.lastName}`} src={user.picture}></Avatar>
          ))}
        </AvatarGroup>
        <Flex alignItems="center" gap={1}>
          <MdCalendarMonth />
          <Text fontSize="sm" fontWeight="semibold">
            {format(new Date(app.createdAt), 'MMM dd, yyyy')}
          </Text>
        </Flex>
      </Flex>
      {user && !app.owner.hasActiveSubscription && (
        <InactiveAppDialog
          isOpen={inactiveAppId === app.id}
          onClose={() => setInactiveAppId(null)}
          app={app}
          user={user}
        />
      )}
    </LinkBox>
  );

  return (
    <AuthenticatedLayout title="Apps" icon={MdApps}>
      <SimpleGrid minChildWidth={{ base: '100%', md: '400px'}} width="100%" spacingX="40px" spacingY="20px">
        {user &&
          apps &&
          apps.sort((a, b) => a.name.localeCompare(b.name)).map((app) => <AppCard key={app.id} app={app} />)}
        {user?.hasActiveSubscription && (
          <SoftCard
            onClick={() => navigate('/apps/create')}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            {...cardStyle}
          >
            <Icon boxSize="48px" as={MdAdd} />
            <Heading as="h3" size="sm" textAlign="center">
              Create App
            </Heading>
          </SoftCard>
        )}
      </SimpleGrid>
    </AuthenticatedLayout>
  );
};
