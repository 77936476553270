import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  FormErrorMessage,
  SimpleGrid,
  Flex,
  FormHelperText,
  Switch,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { SoftCard } from '../../components/generic/SoftCard';
import { useAppsContext } from '../../providers';
import { PrivilegeContainer } from '../../components/generic/PrivilegeContainer';
import { Entity, Operation, usePrivileges } from '../../hooks/roles';
import { useAppActions } from '../../hooks';
import { App } from '../../types';
import { ColorPicker } from '../../components/apps/ThemeEditor';
import { AppRunner } from '../../components/apps/AppRunner';
import { ColorMode } from '../../components/generic/ColorMode';
import { ConfirmButton } from '../../components/generic/ConfirmButton';

type DeepNonNullable<T> = { [K in keyof T]: NonNullable<T[K]> };

const formFields = ['name', 'description', 'textColor', 'brandColor', 'backgroundColor', 'waitForCompletion'] as const;
type AppearanceFormFields = DeepNonNullable<Pick<App, (typeof formFields)[number]>>;

export const AppearancePage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const privileges = usePrivileges();

  const { updateApp } = useAppActions();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AppearanceFormFields>({
    defaultValues: {
      name: selectedApp?.name || '',
      description: selectedApp?.description || '',
      backgroundColor: selectedApp?.backgroundColor || '#000000',
      textColor: selectedApp?.textColor || '#ffffff',
      brandColor: selectedApp?.brandColor || 'linear-gradient(to right, #FC4760, var(--chakra-colors-brand-400))',
    },
  });

  if (!selectedApp) return null;

  const onUpdateApp = (data: AppearanceFormFields) => {
    updateApp({ ...data, id: selectedApp?.id });
  };

  const backgroundColor = watch('backgroundColor');
  const textColor = watch('textColor');
  const brandColor = watch('brandColor');

  return (
    <Flex flexDir="column" gap={4}>
      <form onSubmit={handleSubmit(onUpdateApp)}>
        <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={6}>
          <SoftCard title="Information">
            <FormControl isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                disabled={!privileges.APPS.UPDATE}
                mb="4"
                {...register('name', { required: 'Name is required' })}
              />
              {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="description">Description</FormLabel>
              <Textarea disabled={!privileges.APPS.UPDATE} mb="4" {...register('description', { required: false })} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="waitForCompletion">Wait for completion</FormLabel>

              <Switch
                id="waitForCompletion"
                defaultChecked={selectedApp.waitForCompletion}
                size="md"
                onChange={async (e) => setValue('waitForCompletion', e.target.checked)}
              />
              <FormHelperText mb="4">
                By disabling this switch, your app will instantly complete after pressing "Submit". This is useful if
                you want to do processing in the background without showing the result to the user.
              </FormHelperText>
            </FormControl>
            <Box>
              <PrivilegeContainer entity={Entity.APPS} operation={Operation.UPDATE}>
                <Button type="submit">Save</Button>
              </PrivilegeContainer>
            </Box>
          </SoftCard>
          <SoftCard title="Colors">
            <Flex flexDirection="column" flexGrow={1}>
              <ColorPicker
                name="Background color"
                tooltip="The background color in your app"
                color={backgroundColor}
                valueName="backgroundColor"
                setValue={setValue}
              />
              <ColorPicker
                name="Text color"
                tooltip="The text color in your app"
                color={textColor}
                valueName="textColor"
                setValue={setValue}
              />
              <ColorPicker
                name="Brand color"
                tooltip="The color of elements such as buttons in your app"
                color={brandColor}
                valueName="brandColor"
                setValue={setValue}
              />
            </Flex>
            <Box>
              <PrivilegeContainer entity={Entity.APPS} operation={Operation.UPDATE}>
                <Button type="submit" mr={4}>
                  Save
                </Button>
                <ConfirmButton
                  variant="outline"
                  onClick={() => {
                    updateApp({ id: selectedApp.id, textColor: null, backgroundColor: null, brandColor: null });
                    reset();
                  }}
                  title="Reset default"
                  cta="Reset"
                  body="Are you sure you want to reset to the default appearance?"
                />
              </PrivilegeContainer>
            </Box>
          </SoftCard>
        </SimpleGrid>
      </form>

      <SoftCard title="Preview">
        <ColorMode color={backgroundColor}>
          <Box
            padding="6"
            background={backgroundColor}
            borderRadius="6"
            mt="4"
            mb="4"
            color={textColor}
            maxHeight={500}
            overflowY="scroll"
          >
            <AppRunner app={{ ...selectedApp, brandColor }} isPublic={false} disabled />
          </Box>
        </ColorMode>
      </SoftCard>
    </Flex>
  );
};
