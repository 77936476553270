import { AddIcon } from '@chakra-ui/icons';
import { Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { contextVariables } from './mappings';

type ContextMenuProps = {
  onSelect: ({ key, value }: { key: string; value: string }) => void;
};

export const ContextMenu: React.FC<ContextMenuProps> = ({ onSelect }) => {
  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton as={Tag} _hover={{ cursor: 'pointer' }} variant="outline">
            <Flex alignItems="center">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Context</TagLabel>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuGroup title="Date & Time">
              {contextVariables.map((item) => (
                <MenuItem
                  paddingInlineStart={4}
                  fontSize="sm"
                  key={item.value}
                  onClick={() => {
                    onClose();
                    onSelect({ key: item.text, value: item.value });
                  }}
                >
                  {item.text}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
