import React from 'react';

import { Box, Flex, Modal, ModalContent, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { InfoIcon } from '@chakra-ui/icons';

type FormProps = {
  backgroundColor: string;
  brandColor: string;
  textColor: string;
};

export const ColorPicker = ({
  name,
  color,
  tooltip,
  valueName,
  setValue,
}: {
  name: string;
  color: string;
  tooltip: string;
  valueName: keyof FormProps;
  setValue: (value: keyof FormProps, color: string) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  return (
    <Flex flexDir="row" alignItems="center" justifyContent="space-between">
      <Flex>
        <Text as="b">{name}</Text>
        <Tooltip label={tooltip} fontSize="md">
          <InfoIcon boxSize={3} mt="1.5" color="#999" ml="1.5" />
        </Tooltip>
      </Flex>
      <Box>
        <Box
          borderRadius={4}
          mt={2}
          height={8}
          width={8}
          backgroundImage={color}
          background={color}
          _hover={{ cursor: 'pointer', background: color }}
          onClick={onOpen}
        />
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
          <ModalContent w="auto">
            <HexColorPicker color={color} onChange={(color) => setValue(valueName, color)} />
            <HexColorInput
              style={{ padding: '8px', marginTop: '8px', background: 'white', color: 'black', borderRadius: '6px' }}
              color={color}
              onChange={(color) => setValue(valueName, color)}
            />
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};
