import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppsContext } from '../providers';
import { App } from '../types';
import { useApiActions } from './api-actions';
import { AppConfig } from '@packages/clevis';

export type ListAppsResponse = {
  apps: App[];
};

type CreateAppResponse = {
  app: App;
};

type UpdateAppResponse = {
  app: App;
};

export const useAppActions = () => {
  const navigate = useNavigate();
  const { apps, selectedApp, setSelectedApp, setApps } = useAppsContext();
  const { executeApiAction } = useApiActions();

  const listApps = () =>
    executeApiAction<App[]>({
      action: async ({ client }) => (await client.get('apps').json<ListAppsResponse>()).apps,
      onSuccess: (res: App[]) => {
        setApps(res);
      },
    });

  const createApp = async ({
    name,
    description,
    appConfig,
  }: {
    name: string;
    description?: string;
    appConfig?: AppConfig;
  }) =>
    await executeApiAction({
      action: async ({ client }) =>
        (await client.post('apps', { json: { name, description, appConfig } }).json<CreateAppResponse>()).app,
      onSuccess: (app: App) => {
        setApps([...apps, app]);
        setSelectedApp(app);
        navigate(`/apps/${app.id}/editor`);
      },
      errorMessage: 'Failed to create app',
      successMessage: 'App created',
    });

  const updateApp = async ({ id, ...rest }: Pick<App, 'id'> & Partial<App>) =>
    await executeApiAction({
      action: ({ client }) => client.put(`apps/${id}`, { json: { ...rest } }).json<UpdateAppResponse>(),
      onSuccess: ({ app }: UpdateAppResponse) => {
        if (app.id === selectedApp?.id) {
          setSelectedApp(app);
        }
        setApps(apps.map((p) => (p.id === app.id ? app : p)));
      },
      errorMessage: 'Failed to update app',
      successMessage: 'App saved',
    });

  const deleteApp = async (id: string) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`apps/${id}`),
      onSuccess: () => {
        setApps(apps.filter((p) => p.id !== id));
        if (selectedApp?.id === id) {
          setSelectedApp(null);
        }

        navigate('/apps');
      },
      errorMessage: 'Failed to delete app',
      successMessage: 'App deleted',
    });

  const updateSecret = async ({ appId, key, value }: { appId: string; key: string; value: string }) =>
    await executeApiAction({
      action: ({ client }) => client.put(`apps/${appId}/secrets/${key}`, { json: { value } }).json<UpdateAppResponse>(),
      onSuccess: ({ app }: UpdateAppResponse) => {
        if (app.id === selectedApp?.id) {
          setSelectedApp(app);
        }
        setApps(apps.map((p) => (p.id === app.id ? app : p)));
      },
      errorMessage: 'Failed to update secret',
      successMessage: 'Secret updated',
    });

  const createSecret = async ({ appId, key, value }: { appId: string; key: string; value: string }) =>
    await executeApiAction({
      action: ({ client }) => client.post(`apps/${appId}/secrets`, { json: { key, value } }).json<UpdateAppResponse>(),
      onSuccess: ({ app }: UpdateAppResponse) => {
        if (app.id === selectedApp?.id) {
          setSelectedApp(app);
        }
        setApps(apps.map((p) => (p.id === app.id ? app : p)));
      },
      errorMessage: 'Failed to create secret',
      successMessage: 'Secret created',
    });

  const deleteSecret = async ({ appId, key }: { appId: string; key: string }) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`apps/${appId}/secrets/${key}`).json<UpdateAppResponse>(),
      onSuccess: ({ app }: UpdateAppResponse) => {
        if (app.id === selectedApp?.id) {
          setSelectedApp(app);
        }
        setApps(apps.map((p) => (p.id === app.id ? app : p)));
      },
      errorMessage: 'Failed to delete secret',
      successMessage: 'Secret deleted',
    });

  return {
    listApps: useCallback(listApps, [setApps, executeApiAction]),
    createApp: useCallback(createApp, [setApps, setSelectedApp, executeApiAction, navigate, apps]),
    updateApp: useCallback(updateApp, [setApps, setSelectedApp, selectedApp, executeApiAction, apps]),
    deleteApp: useCallback(deleteApp, [setApps, setSelectedApp, selectedApp, executeApiAction, apps, navigate]),
    updateSecret: useCallback(updateSecret, [executeApiAction, setSelectedApp, setApps, apps, selectedApp]),
    createSecret: useCallback(createSecret, [executeApiAction, setSelectedApp, setApps, apps, selectedApp]),
    deleteSecret: useCallback(deleteSecret, [executeApiAction, setSelectedApp, setApps, apps, selectedApp]),
  };
};
