import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Flex,
  LinkBox,
  LinkOverlay,
  Progress,
  Spinner,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppsContext, useAuthContext } from '../providers';
import { SoftCard } from './generic/SoftCard';
import { useApiActions } from '../hooks/api-actions';

type UsageResponse = {
  used: number;
  available: number;
};

const WrapperCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <SoftCard p={4} width="100%">
      <Flex justifyContent="center" alignItems="center" flexGrow={1}>
        {children}
      </Flex>
    </SoftCard>
  );
};

export const UsageCard = () => {
  const { selectedApp } = useAppsContext();
  const { user } = useAuthContext();
  const [usage, setUsage] = useState<UsageResponse | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { executeApiAction } = useApiActions();

  useEffect(() => {
    const fetchUsage = async () => {
      if (!selectedApp) return;
      await executeApiAction<UsageResponse>({
        action: ({ client }) => {
          setIsLoading(true);
          return client.get(`${selectedApp.id}/usage`).json<UsageResponse>();
        },
        onSuccess: (response: UsageResponse) => {
          setUsage(response);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    };

    fetchUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgressColor = (used: number, available: number) => {
    const usage = Math.round((used / available) * 100);

    if (usage >= 100) {
      return 'red';
    } else if (usage >= 75) {
      return 'orange';
    }

    return 'brand';
  };

  if (!selectedApp) return null;

  if (isLoading) {
    return (
      <WrapperCard>
        <Spinner />
      </WrapperCard>
    );
  }

  return (
    <Flex flexDir="column" gap={2} w="full">
      {usage && usage.used / usage.available > 0.75 && (
        <LinkBox as={Alert} status="warning">
          <AlertIcon />
          <AlertDescription>You are almost out of credits, click here to upgrade your plan</AlertDescription>
          <LinkOverlay as={RouterLink} to="/settings/billing" />
        </LinkBox>
      )}

      <LinkBox as={WrapperCard}>
        <Flex flexDirection="column" gap={2} flexGrow={1}>
          <Flex flexDirection="column">
            <Flex justifyContent="space-between" flexDirection="row">
              <Text fontSize="md" fontWeight="bold">
                {`${_.capitalize(selectedApp.owner.planId)} Plan`}
              </Text>
              {user?.isTrialing && (
                <Badge w="50" pt="3px">
                  TRIAL
                </Badge>
              )}
            </Flex>

            <LinkOverlay as={RouterLink} to="/settings/billing" />
          </Flex>
          {usage && (
            <>
              <Flex flexDirection="column" gap={1}>
                <Flex justifyContent="space-between">
                  <Text fontSize="sm">Used credits</Text>
                  <Text fontSize="sm">{`${Intl.NumberFormat('en-US').format(usage.used)} / ${Intl.NumberFormat(
                    'en-US'
                  ).format(usage?.available || 0)}`}</Text>
                </Flex>
                <Progress
                  value={(usage.used / usage.available) * 100}
                  size="sm"
                  borderRadius={2}
                  colorScheme={getProgressColor(usage.used, usage.available)}
                />
              </Flex>
            </>
          )}
        </Flex>
      </LinkBox>
    </Flex>
  );
};
