import {
  Text,
  Box,
  Flex,
  Button,
  Spacer,
  Icon,
  Badge,
  Heading,
  Spinner,
  useDisclosure,
  Tooltip,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { MdEdit, MdDelete, MdLightbulbOutline, MdWarning, MdPreview, MdAutoFixHigh } from 'react-icons/md';
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from '@chakra-ui/icons';

import { useState } from 'react';
import {
  StepConfig,
  StepStateCallbackMap,
  StepStatus,
  StepTypeConfig,
  getCost,
  getInitialInputs,
  validateApp,
} from '@packages/clevis';

import { SoftCard } from '../../components/generic/SoftCard';
import { StepEditor } from '../../components/steps/StepEditor';
import { StepTypeMetadataMap } from '../../components/steps/types';
import { ConfirmButton } from '../../components/generic/ConfirmButton';
import { useAppsContext } from '../../providers';
import { useAppActions } from '../../hooks';
import { AppRunner } from '../../components/apps/AppRunner';
import { getWarning } from '../../components/steps/utils';
import { AssistantDrawer, TemplateDrawer } from '../../components/drawers';

export const EditorPage: React.FC = () => {
  const { selectedApp, setSelectedApp } = useAppsContext();
  const [shineIndex, setShineIndex] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [editingStepData, setEditingStepData] = useState<{ step: StepConfig<StepTypeConfig>; index: number } | null>(
    null
  );
  const [runState, setRunState] = useState<StepStateCallbackMap | undefined>();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: templatesIsOpen, onClose: onTemplateClose, onOpen: onTemplateOpen } = useDisclosure();
  const { isOpen: assistantIsOpen, onClose: onAssistantClose, onOpen: onAssistantOpen } = useDisclosure();

  const { updateApp } = useAppActions();

  if (!selectedApp) return;
  const steps = selectedApp.appConfig?.steps || [];

  const calculateCost = () => {
    if (steps && steps.length > 0) {
      return getCost({ steps });
    }

    return 0;
  };

  const validate = (steps: StepConfig<StepTypeConfig>[]) => {
    try {
      const result = validateApp({ steps });
      setErrorMessage(null);
      return result;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error?.message);
      } else {
        setErrorMessage('Invalid app');
      }

      throw error;
    }
  };

  const moveStep = async (index: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    if (newIndex < 0 || newIndex >= steps.length) {
      return; // Invalid index, cannot move element
    }

    const adjacentStep = steps[newIndex];
    const updatedSteps = [...steps];
    updatedSteps[newIndex] = steps[index];
    updatedSteps[index] = adjacentStep;

    try {
      const result = validate(updatedSteps);
      await updateApp({
        id: selectedApp.id,
        appConfig: {
          steps: updatedSteps,
        },
      });

      setSelectedApp({ ...selectedApp, appConfig: result });
    } catch (error: unknown) {
      return;
    }

    setShineIndex(newIndex);
    setTimeout(() => setShineIndex(null), 3000);
  };

  const saveStep = async (step: StepConfig<StepTypeConfig>, index?: number) => {
    const updatedSteps = [...steps];

    if (index === undefined) updatedSteps.push(step);
    if (index !== undefined) updatedSteps[index] = step;

    try {
      const result = validate(updatedSteps);
      await updateApp({
        id: selectedApp.id,
        appConfig: result,
      });
      setEditingStepData(null);
      setErrorMessage(null);
      setSelectedApp({ ...selectedApp, appConfig: { steps: updatedSteps } });
    } catch (err: unknown) {
      return;
    }
  };

  const deleteStep = async (index: number) => {
    const updatedSteps = steps.filter((_, i) => index !== i);

    await updateApp({
      id: selectedApp.id,
      appConfig: {
        steps: updatedSteps,
      },
    });

    setSelectedApp({ ...selectedApp, appConfig: { steps: updatedSteps } });
  };

  const getBorderStyles = (stepId: string) => {
    if (!runState) {
      return {};
    }

    switch (runState[stepId]?.status) {
      case StepStatus.COMPLETED:
        return { boxSizing: 'border-box', borderWidth: 1, borderStyle: 'solid', borderColor: 'green.700' };
      case StepStatus.RUNNING:
        return { boxSizing: 'border-box', borderWidth: 1, borderStyle: 'solid', borderColor: 'blue.700' };
      case StepStatus.FAILED:
        return { boxSizing: 'border-box', borderWidth: 1, borderStyle: 'solid', borderColor: 'red.700' };
      default:
        return { boxSizing: 'border-box', borderWidth: 1, borderStyle: 'solid', borderColor: 'gray.700' };
    }
  };

  return (
    <>
      <Flex flexDir="column" gap={4}>
        {steps.length === 0 && (
          <>
            <Heading size="lg">Get started building your app</Heading>
            <Text mb="2">
              Choose how to get started. Either use one of our pre-made templates, let our AI assistant generate your
              app for you or start from scratch by adding your first step.
            </Text>
            <Flex dir="column" gap="8">
              <SoftCard w="100%" onClick={onTemplateOpen} cursor="pointer" alignItems="center">
                <Icon boxSize="36px" as={MdLightbulbOutline} />
                <Text mt={2}>Use a template</Text>
              </SoftCard>
              <SoftCard w="100%" onClick={onAssistantOpen} cursor="pointer" alignItems="center">
                <Icon boxSize="36px" as={MdAutoFixHigh} />
                <Text mt={2}>Generate with AI assistant</Text>
              </SoftCard>
            </Flex>
            <Flex py="1" flexDir="row" width="100%" alignItems="center">
              <Divider />
              <Text as="b" paddingStart={3} paddingEnd={3}>
                or
              </Text>
              <Divider />
            </Flex>
          </>
        )}
        {steps.length > 0 && (
          <>
            <SoftCard p={0} py={2} px={8}>
              <Flex alignItems="center">
                <Stat>
                  <StatLabel>
                    Cost
                    <Tooltip label="The number of credits your app will consume each time it is run" fontSize="md">
                      <InfoIcon boxSize={3} color="gray.400" ml="1" />
                    </Tooltip>
                  </StatLabel>
                  <StatNumber>{calculateCost()} </StatNumber>
                </Stat>

                <Flex gap={2}>
                  <Button leftIcon={<MdAutoFixHigh />} onClick={onAssistantOpen}>
                    AI Assistant
                  </Button>
                  <Button leftIcon={<MdLightbulbOutline />} onClick={onTemplateOpen}>
                    Templates
                  </Button>
                  <Button leftIcon={<MdPreview />} onClick={onOpen}>
                    Preview
                  </Button>
                </Flex>
              </Flex>
            </SoftCard>
            <Heading as="h2" size="md">
              Steps
            </Heading>

            {steps.map((step, index) => {
              const { title, description, icon } = StepTypeMetadataMap[step.type];
              const warning = getWarning(step, selectedApp.appConfig?.steps.map((step) => step.id) || []);
              return (
                <SoftCard
                  key={index}
                  className={index === shineIndex ? 'shine-animation' : null}
                  id={index}
                  mb={4}
                  p={0}
                  transition="border 250ms linear"
                  {...getBorderStyles(step.id)}
                >
                  <Flex px={8} py={4} gap={4} justifyContent="center" alignItems="center">
                    <Flex display="flex" alignItems="center" mr={4}>
                      {runState && runState[step.id]?.status === StepStatus.RUNNING ? (
                        <Spinner />
                      ) : (
                        <Icon flex={1} boxSize={6} as={icon} />
                      )}
                    </Flex>
                    <Flex minW="120">
                      <Box>
                        <Badge textTransform={'none'}>{step.id}</Badge>
                        <Text fontSize="md">{title}</Text>
                      </Box>
                    </Flex>
                    <Flex>
                      <Text fontSize="md">{description}</Text>
                    </Flex>
                    <Spacer />
                    <Flex gap={2}>
                      {warning && (
                        <Tooltip label={warning}>
                          <Button iconSpacing={0} rightIcon={<MdWarning />} variant={'warning'} />
                        </Tooltip>
                      )}
                      <Button
                        iconSpacing={0}
                        rightIcon={<MdEdit />}
                        onClick={() => setEditingStepData({ step, index })}
                      />
                      <ConfirmButton
                        icon={<MdDelete />}
                        onClick={async () => await deleteStep(index)}
                        body={`Are you sure you want to delete the "${title}" step?`}
                        colorScheme="red"
                      />
                    </Flex>
                    <Flex alignItems="center" justifyContent="center" flexDirection="column">
                      <ChevronUpIcon
                        cursor={'pointer'}
                        onClick={async () => {
                          await moveStep(index, 'up');
                        }}
                        boxSize={6}
                      />
                      <ChevronDownIcon
                        cursor={'pointer'}
                        onClick={async () => {
                          await moveStep(index, 'down');
                        }}
                        boxSize={6}
                      />
                    </Flex>
                  </Flex>
                </SoftCard>
              );
            })}
          </>
        )}

        <StepEditor
          stepData={editingStepData !== null ? editingStepData : undefined}
          errorMessage={errorMessage}
          onCloseError={() => setErrorMessage(null)}
          save={saveStep}
          cancel={() => {
            setEditingStepData(null);
            setErrorMessage(null);
          }}
        />
      </Flex>

      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent overflow="scroll">
          <DrawerCloseButton />

          <DrawerHeader>Preview</DrawerHeader>
          <DrawerBody>
            <AppRunner
              app={{ ...selectedApp, appConfig: { steps: getInitialInputs(steps) } }}
              isPublic={false}
              onStateUpdate={(state) => {
                setRunState(state);
              }}
              onReset={() => {
                setRunState(undefined);
              }}
            />
          </DrawerBody>
          <DrawerFooter />
        </DrawerContent>
      </Drawer>

      <TemplateDrawer isOpen={templatesIsOpen} onClose={onTemplateClose} />

      <AssistantDrawer isOpen={assistantIsOpen} onClose={onAssistantClose} />
    </>
  );
};
