import { Outlet } from 'react-router-dom';
import { SettingsSidebarLayout } from '../layouts/settings-sidebar';

export const SettingsRoute = () => {
  return (
    <SettingsSidebarLayout>
      <Outlet />
    </SettingsSidebarLayout>
  );
};
