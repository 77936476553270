export enum ErrorNames {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  MISSING_PROPERTY_ERROR = 'MISSING_PROPERTY_ERROR',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
  SUBCRIPTION_REQUIRED = 'SUBCRIPTION_REQUIRED',
  APP_DOES_NOT_EXIST = 'APP_DOES_NOT_EXIST',
  APP_INVITATION_INVALID = 'APP_INVITATION_INVALID',
  APP_INVITATION_EXPIRED = 'APP_INVITATION_EXPIRED',
  SUBSCRIPTION_REQUIRED = 'SUBSCRIPTION_REQUIRED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  ENVIRONMENT_ERROR = 'ENVIRONMENT_ERROR',
  RUNNER_ERROR = 'RUNNER_ERROR',
  RENDER_ERROR = 'RENDER_ERROR',
  EXECUTION_ERROR = 'EXECUTION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
}
