import { StatusCodes } from 'http-status-codes';

import { ErrorNames } from './error-names';

interface ErrorProperties {
  name: ErrorNames;
  message: string;
  debug?: string;
  statusCode: StatusCodes;
  details?: string;
}

export class ServiceError extends Error {
  public statusCode: StatusCodes;
  public debug?: string;
  public details?: string;

  constructor({ name, message, statusCode, debug, details }: ErrorProperties) {
    super(message);
    this.name = name;
    this.statusCode = statusCode;
    this.debug = debug;
    this.details = details;
  }
}
