import { ChatGPTRole, ChatGptModel, StepType, TextInputType } from '@packages/clevis';
import { Template } from '../types';

export const characterCreator: Template = {
  title: 'Character Creator',
  description:
    'Need character ideas for your next novel? The Character Creator app will generate a character for you with a portrait and a backstory',
  appConfig: {
    steps: [
      {
        id: 'name',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter the name of your character',
          inputType: TextInputType.INPUT,
        },
      },
      {
        id: 'year',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Enter the year your character lives in',
          inputType: TextInputType.INPUT,
        },
      },
      {
        id: 'gpt',
        type: StepType.CHATGPT,
        config: {
          model: ChatGptModel.GPT_3_5_TURBO,
          messages: [
            {
              role: ChatGPTRole.USER,
              content:
                'Write a made up character biography in three sentences for a character called {{steps.name.output}} who lived in the year {{steps.year.output}}',
            },
          ],
        },
      },
      {
        id: 'image',
        type: StepType.DALL_E,
        config: {
          size: '1024x1024',
          number: 1,
          prompt:
            'Create a portrait of someone called {{steps.name.output}} who lived in the year {{ steps.year.output }}',
          style: 'vivid',
        },
      },
      {
        id: 'output',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '<img src="{{steps.image.output.images[0].url}}" />\n<p>{{steps.gpt.output}}</p>',
        },
      },
    ],
  },
};
