import { AddIcon } from '@chakra-ui/icons';
import {
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { getVariables } from './helpers';
import { useAppsContext } from '../../../providers';
import { useFormContext } from 'react-hook-form';
import { StepConfig, StepTypeConfig } from '@packages/clevis/src';
import React from 'react';

type VariablesMenuProps = {
  onSelect: ({ key, value }: { key: string; value: string }) => void;
};

export const VariablesMenu: React.FC<VariablesMenuProps> = ({ onSelect }) => {
  const { selectedApp } = useAppsContext();
  const { watch } = useFormContext<StepConfig<StepTypeConfig>>();

  const stepId = watch('id');

  const variables = getVariables(selectedApp?.appConfig || null, stepId);

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton as={Tag} _hover={{ cursor: 'pointer' }} variant="outline">
            <Flex alignItems="center">
              <TagLeftIcon boxSize="12px" as={AddIcon} />
              <TagLabel>Variables</TagLabel>
            </Flex>
          </MenuButton>
          <MenuList maxHeight={200} overflow="scroll">
            {variables.length > 0 ? (
              variables.map(({ stepId, outputs }, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <Divider />}
                  <MenuGroup key={stepId} title={stepId}>
                    {outputs.map((output) => (
                      <MenuItem
                        paddingInlineStart={4}
                        fontSize="sm"
                        key={output.text}
                        onClick={(event) => {
                          event?.preventDefault();
                          onClose();
                          onSelect({ key: output.text, value: output.value });
                        }}
                      >
                        {output.text}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </React.Fragment>
              ))
            ) : (
              <MenuItem>No Variables</MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
