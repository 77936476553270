import { AppConfig, RunInput, StepStateCallbackMap } from '@packages/clevis';

export enum RunStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  INPUT_REQUIRED = 'INPUT_REQUIRED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type RunError = {
  name: string;
  message: string;
  stepId?: string;
  details?: string;
};

export type Run = {
  id: string;
  appId: string;
  status: RunStatus;
  input: RunInput;
  state?: StepStateCallbackMap;
  error?: RunError;
  consumedCredits?: number;
  appConfig: AppConfig;
  completedAt?: string;
  createdAt: string;
};
