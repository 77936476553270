/* eslint-disable @typescript-eslint/no-unused-vars */
import { RunnerError, ErrorNames } from '@packages/errors';
import joi from 'joi';
import { appSchema } from './schemas';
import {
  AppConfig,
  StepType,
  StepTypeConfig,
  ChatGPTStepTypeConfig,
  ChatGptModel,
  DallEStepTypeConfig,
  ValidationMessage,
  StepConfig,
  DallEModel,
  DallEQuality,
  CHATGPT_DEFAULT_MAX_TOKENS,
  StepCategory,
} from './types';

const stepCosts: Record<StepType, (input: StepTypeConfig) => number> = {
  [StepType.CHATGPT]: (input: StepTypeConfig) => {
    const config = input as ChatGPTStepTypeConfig;
    const maxTokens = config.maxTokens || CHATGPT_DEFAULT_MAX_TOKENS;
    const tokenFactor = maxTokens / CHATGPT_DEFAULT_MAX_TOKENS;
    switch (config.model) {
      case ChatGptModel.GPT_3_5_TURBO:
        return Math.round(tokenFactor * 8);
      case ChatGptModel.GPT_4:
        return Math.round(tokenFactor * 22);
      case ChatGptModel.GPT_3_5_TURBO_1106:
        return Math.round(tokenFactor * 8);
    }
  },
  [StepType.DALL_E]: (input) => {
    const config = input as DallEStepTypeConfig;
    if (config.model === DallEModel.DALL_E_3) {
      if (config.quality === DallEQuality.HD) {
        switch (config.size) {
          case '1024x1024':
            return 55 * config.number;
          case '1024x1792':
            return 83 * config.number;
          case '1792x1024':
            return 83 * config.number;
          default:
            return 83 * config.number;
        }
      }

      switch (config.size) {
        case '1024x1024':
          return 28 * config.number;
        case '1024x1792':
          return 55 * config.number;
        case '1792x1024':
          return 55 * config.number;
        default:
          return 55 * config.number;
      }
    }

    switch (config.size) {
      case '256x256':
        return 20 * config.number;
      case '512x512':
        return 22 * config.number;
      case '1024x1024':
        return 26 * config.number;
      default:
        return 26 * config.number;
    }
  },
  [StepType.HTTP_REQUEST]: (_) => 1,

  [StepType.GEOLOCATION_INPUT]: (_) => 1,
  [StepType.TEXT_INPUT]: (_) => 0,
  [StepType.DISPLAY_OUTPUT]: (_) => 0,
  [StepType.SELECT_INPUT]: (_) => 0,
  [StepType.SEND_EMAIL]: (_) => 4,
};

export const getStepCost = (step: StepConfig<StepTypeConfig>) => {
  return stepCosts[step.type](step.config);
};

export const getCost = (app: AppConfig) => {
  let cost = 2;

  for (let i = 0; i < app.steps.length; i++) {
    const step = app.steps[i];
    cost += getStepCost(step);
  }

  return cost;
};

export const getStepCategory = (stepType: StepType): StepCategory => {
  const categoryMap: Record<StepType, StepCategory> = {
    [StepType.CHATGPT]: StepCategory.PROCESSING,
    [StepType.DALL_E]: StepCategory.PROCESSING,
    [StepType.HTTP_REQUEST]: StepCategory.PROCESSING,
    [StepType.GEOLOCATION_INPUT]: StepCategory.INPUT,
    [StepType.SELECT_INPUT]: StepCategory.INPUT,
    [StepType.TEXT_INPUT]: StepCategory.INPUT,
    [StepType.DISPLAY_OUTPUT]: StepCategory.OUTPUT,
    [StepType.SEND_EMAIL]: StepCategory.OUTPUT,
  };
  return categoryMap[stepType];
};

export const getInitialInputs = (steps: StepConfig<StepTypeConfig>[]) => {
  const initialInputs: StepConfig<StepTypeConfig>[] = [];
  for (const step of steps) {
    const category = getStepCategory(step.type);
    if (category !== StepCategory.INPUT) break;
    initialInputs.push(step);
  }
  return initialInputs;
};

const createValidationMessageFromJoiErrors = (
  config: AppConfig,
  joiErrors: joi.ValidationErrorItem[]
): ValidationMessage => {
  const joiError = joiErrors[0];
  const [steps, index] = joiError.path;
  const stepPath = `steps[${index}]`;
  const stepId = config.steps[Number(index)]?.id;
  return steps === 'steps' && joiError.context?.label
    ? {
        stepId,
        message: joiError.message.replace(`${stepPath}.`, '').replace('config.', '').replace(stepPath, stepId),
      }
    : { message: 'Validation error' };
};

export const validateApp = (config: AppConfig) => {
  const result = appSchema.validate(config);
  if (result.error) {
    const validationMessage = createValidationMessageFromJoiErrors(config, result.error.details);
    throw new RunnerError({
      name: ErrorNames.VALIDATION_ERROR,
      details: result.error.message,
      ...validationMessage,
    });
  }
  return result.value;
};
