import { StepType } from '@packages/clevis';
import { Template } from '../types';

export const resumeParser: Template = {
  title: 'Resume Parser',
  description:
    'This app makes use of ChatGPTs ability to provide structured output. The app shows how to transform unstructured text such as a Resume to structured output like JSON',
  appConfig: {
    steps: [
      {
        id: 'resume-input',
        type: StepType.TEXT_INPUT,
        config: {
          title: 'Paste the applicants resume',
          inputType: 'TEXT_AREA',
          helperText: '',
        },
      },
      {
        id: 'parse-resume',
        type: StepType.CHATGPT,
        config: {
          model: 'gpt-4',
          messages: [
            {
              role: 'user',
              content: 'Read the generated resume and create a JSON object from it.',
            },
            {
              role: 'user',
              content: 'Here is the applicants resume:\n\n"{{ steps.resume-input.output }}"',
            },
          ],
          jsonSchema:
            '{\n  "type": "object",\n  "properties": {\n    "university": {\n      "type": "string",\n      "description": "The university that the applicant studied at"\n    },\n    "name": {\n      "type": "string",\n      "description": "The name of the applicant"\n    },\n    "email": {\n      "type": "string",\n      "description": "The email of the applicant"\n    },\n    "phone": {\n      "type": "string",\n      "description": "The applicants phone number"\n    },\n    "skills": {\n      "type": "array",\n      "items": {\n        "type": "string"\n      },\n      "description": "A list of the users skills"\n    },\n    "languages": {\n      "type": "array",\n      "items": {\n        "type": "string"\n      },\n      "description": "A list of languages the applicant speaks"\n    },\n    "companies": {\n      "type": "array",\n      "items": {\n        "type": "string"\n      },\n      "description": "A list of companies the applicant has worked at"\n    }\n  }\n}\n',
        },
      },

      {
        id: 'display',
        type: StepType.DISPLAY_OUTPUT,
        config: {
          output: '{{steps.parse-resume.output | json}}',
        },
      },
    ],
  },
};
