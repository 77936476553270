import { FormControl, FormLabel, VStack, Select } from '@chakra-ui/react';
import { DallEModel, DallEQuality, DallEStepTypeConfig, DallEStyle, StepConfig } from '@packages/clevis';
import { useFormContext } from 'react-hook-form';
import { TemplateArea } from './TemplateArea';
import { useEffect } from 'react';

export const DallEStep = () => {
  const { register, control, watch, unregister, setValue } = useFormContext<StepConfig<DallEStepTypeConfig>>();

  const watchModel = watch('config.model', DallEModel.DALL_E_3);

  const sizes =
    watchModel === DallEModel.DALL_E_3
      ? (['1024x1024', '1024x1792', '1792x1024'] as `${number}x${number}`[])
      : (['1024x1024', '512x512', '256x256'] as `${number}x${number}`[]);

  useEffect(() => {
    if (watchModel === DallEModel.DALL_E_2) {
      unregister('config.style');
      unregister('config.quality');
    }
    setValue('config.size', sizes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchModel]);

  return (
    <VStack spacing="4">
      <FormControl isRequired>
        <FormLabel htmlFor="model" fontSize="sm">
          Model
        </FormLabel>
        <Select
          {...register('config.model', { required: 'Select a model' })}
          fontSize="sm"
          defaultValue={DallEModel.DALL_E_3}
        >
          {Object.values(DallEModel).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel mt={2} htmlFor="output" fontSize="sm">
          Prompt
        </FormLabel>
        <TemplateArea
          name="config.prompt"
          fontSize="sm"
          placeholder="Modernistic style painting of a woman standing in a sunny garden"
          variableTypes={['variables', 'secrets', 'custom', 'contexts']}
          control={control}
        />
      </FormControl>

      {watchModel === DallEModel.DALL_E_3 && (
        <>
          <FormControl isRequired>
            <FormLabel mt={2} htmlFor="style" fontSize="sm">
              Style
            </FormLabel>
            <Select {...register('config.style', { required: 'Select a style' })}>
              {Object.values(DallEStyle).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel mt={2} htmlFor="quality" fontSize="sm">
              Quality
            </FormLabel>
            <Select {...register('config.quality', { required: 'Select a quality' })}>
              {Object.values(DallEQuality).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      <FormControl isRequired>
        <FormLabel mt={2} htmlFor="number" fontSize="sm">
          Number of images
        </FormLabel>
        <Select
          {...register('config.number', { required: 'Enter a valid number', valueAsNumber: true })}
          defaultValue={1}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </Select>
      </FormControl>

      <FormControl isRequired>
        <FormLabel mt={2} htmlFor="size" fontSize="sm">
          Size
        </FormLabel>
        <Select {...register('config.size', { required: 'Enter a valid size' })} defaultValue={'1024x1024'}>
          {sizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
      </FormControl>
    </VStack>
  );
};
