import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';

import { FileUploadForm } from '../../components/forms/FileUploadForm';
import { useAppsContext } from '../../providers';
import { useApiActions } from '../../hooks/api-actions';

interface FormProps {
  key: string;
}

type DrawerProps = { isOpen: boolean; onClose: () => void; onUploadComplete: () => Promise<void> };

export const FileDrawer: React.FC<DrawerProps> = ({ isOpen, onClose, onUploadComplete }) => {
  const { executeApiAction } = useApiActions();
  const { selectedApp } = useAppsContext();
  const [uploadedFile, setUploadedFile] = useState<FormProps>();
  const { onOpen } = useDisclosure();
  const { isOpen: alertIsOpen, onOpen: alertOnOpen, onClose: alertOnClose } = useDisclosure();

  const createFile = ({ key, name }: { key: string; name: string }) =>
    executeApiAction({
      action: ({ client }) => client.post(`apps/${selectedApp?.id}/files`, { json: { name, key } }),
      errorMessage: 'Failed to upload file',
    });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <DrawerCloseButton />
        <DrawerHeader>Upload file</DrawerHeader>
        <DrawerBody>
          <Box mb={4}>
            <Text>
              Upload a file to use it in your app. Currently, only PDFs and CSV files are allowed. Contact us if you
              require additional file formats.
            </Text>
          </Box>
          {alertIsOpen && uploadedFile && (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              mb={4}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                File Uploaded!
              </AlertTitle>
              <CloseButton alignSelf="flex-end" position="absolute" right={2} top={2} onClick={alertOnClose} />
            </Alert>
          )}

          <FileUploadForm
            onSubmit={async ({ file }) => {
              setUploadedFile({ key: file.key });
              alertOnOpen();
              await createFile(file);
              onUploadComplete();
              onOpen();
            }}
          />
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};
