import { Fade, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const FadeInElement = ({ text }: { text: string }) => (
  <Fade in={true} transition={{ exit: { duration: 1 }, enter: { duration: 1 } }}>
    <Text as="b">{text}</Text>
  </Fade>
);

export const SpinnerTexts = () => {
  const [index, setIndex] = useState(0);

  const loadingTexts = [
    '',
    'Loading...',
    'Working...',
    'Still working...',
    'Sit tight, it can take a while...',
    'It can take a couple of minutes, hang in there...',
  ];

  const nextTextItem = (index: number) => {
    if (index + 1 < loadingTexts.length) {
      return setTimeout(() => {
        setIndex(index + 1);
        nextTextItem(index + 1);
      }, 2000 * index);
    }
  };

  useEffect(() => {
    const timeout = nextTextItem(0);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FadeInElement key={index} text={loadingTexts[index]} />;
};
