import { SelectInputStepTypeConfig, StepConfig } from '@packages/clevis';
import { FormControl, FormLabel, Input, Button, Flex, FormHelperText, Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';

export const SelectInputStep = () => {
  const { register, control, watch } = useFormContext<StepConfig<SelectInputStepTypeConfig>>();
  const { fields, append, remove } = useFieldArray<StepConfig<SelectInputStepTypeConfig>>({
    control,
    rules: { minLength: 1 },
    name: 'config.options',
  });

  const options = watch('config.options');

  useEffect(() => {
    if (!fields || fields.length === 0) append({ text: '', value: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex gap={4} flexDir="column">
      <FormControl isRequired>
        <FormLabel htmlFor="title" fontSize="sm">
          Title
        </FormLabel>
        <Input
          id="title"
          type="text"
          fontSize="sm"
          {...register('config.title', { required: 'Enter a valid title' })}
        />
        <FormHelperText> The title appears above the input field in your app</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="helperText" fontSize="sm">
          Helper text
        </FormLabel>
        <Input id="helperText" type="helperText" fontSize="sm" {...register('config.helperText')} />
        <FormHelperText>Text displayed under the input with additional information</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="options" fontSize="sm">
          Options
        </FormLabel>
        <Flex flexDirection="column" gap={4}>
          {fields.map((_, index) => (
            <React.Fragment key={index}>
              <Flex gap={2}>
                <Input
                  fontSize="sm"
                  placeholder="Option"
                  {...register(`config.options.${index}.text` as const, { required: 'Enter a text' })}
                />
                <Input
                  fontSize="sm"
                  placeholder="Value"
                  {...register(`config.options.${index}.value` as const, { required: 'Enter a value' })}
                />
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
        <FormHelperText>Add options for the user to select from</FormHelperText>
        {options && (
          <Box mt={4}>
            <Button onClick={() => append({ text: '', value: '' })} iconSpacing={0} leftIcon={<MdAdd />} size="sm" />
            <Button
              iconSpacing={0}
              leftIcon={<MdDelete />}
              ml="4"
              colorScheme="red"
              isDisabled={options.length < 2}
              onClick={() => remove(options.length - 1)}
              size="sm"
            />
          </Box>
        )}
      </FormControl>
    </Flex>
  );
};
