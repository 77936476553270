import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  SlideFade,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import _ from 'lodash';
import { FormProvider, UseFormRegister, useForm } from 'react-hook-form';
import dompurify from 'dompurify';
import { App } from '../../types';
import {
  DisplayOutputStepTypeConfig,
  GeolocationStepTypeConfig,
  SelectInputStepTypeConfig,
  StepConfig,
  StepStateCallbackMap,
  StepType,
  StepTypeConfig,
  TextInputStepTypeConfig,
} from '@packages/clevis';
import { useApiActions } from '../../hooks/api-actions';
import { Run, RunError, RunStatus } from '../../types/run';
import { MdRepeat } from 'react-icons/md';
import { GeolocationInput, TextInput } from './inputs';
import { SelectInput } from './inputs/SelectInput';
import { getBrandButtonProps } from './utils';
import { isStepType } from '../../utils/is-step-type';
import { SpinnerTexts } from './SpinnerTexts';

type RunResult = {
  id: string;
  waitForCompletion: boolean;
};

const AppContainer = ({ children }: { children: React.ReactNode }) => (
  <Flex flexDirection="column" gap={4} my={4}>
    {children}
  </Flex>
);

type AppRunnerProps = {
  app: App;
  isPublic: boolean;
  onStateUpdate?: (state?: StepStateCallbackMap, status?: RunStatus) => void;
  onReset?: () => void;
  purchaseId?: string;
  disabled?: boolean;
};

export type RunAppInput = {
  [stepId: string]: string | Record<string, unknown>;
};

const RunErrorAlert = ({ isPublic, error }: { isPublic: boolean; error: RunError }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <Box>
        <AlertTitle>Run failed</AlertTitle>
        {isPublic ? (
          <AlertDescription>There was an issue when running the app, please try again later.</AlertDescription>
        ) : (
          <>
            {error.stepId && <Text mb={2}>{`Error in step "${error.stepId}".`}</Text>}
            <Text>
              {error.message}
              {error.details && `: ${error.details}`}
            </Text>
          </>
        )}
      </Box>
    </Alert>
  );
};

export const AppRunner: React.FC<AppRunnerProps> = ({
  app,
  isPublic,
  onStateUpdate,
  onReset,
  purchaseId,
  disabled,
}) => {
  const { ...methods } = useForm<RunAppInput>();
  const { register, handleSubmit, reset, setValue } = methods;

  const [error, setError] = useState<RunError | undefined>();
  const [run, setRun] = useState<Run>();
  const [currentSteps, setCurrentSteps] = useState(app.appConfig?.steps || []);
  const [status, setStatus] = useState<RunStatus>(RunStatus.INPUT_REQUIRED);

  const { executeApiAction } = useApiActions();

  const fetchResult = async ({ runId }: { runId: string }) => {
    await executeApiAction({
      action: async ({ publicClient, client }) => {
        const clientToUse = isPublic ? publicClient : client;
        const endpoint = isPublic ? `public/apps/${app.slug}/runs/${runId}` : `apps/${app.id}/runs/${runId}`;

        return clientToUse.get(endpoint).json<{ run: Run }>();
      },
      onSuccess: ({ run, currentSteps: newSteps }: { run: Run; currentSteps: StepConfig<StepTypeConfig>[] }) => {
        const { state, status, error } = run;

        if (onStateUpdate) {
          onStateUpdate(state, status);
        }

        setRun(run);
        setStatus(run.status);

        if (newSteps) setCurrentSteps(newSteps);

        if (status === RunStatus.IN_PROGRESS) {
          setTimeout(() => fetchResult({ runId }), 1000);
        } else if (status === RunStatus.FAILED) {
          setError(error);
        }
      },
      onError: (error) => {
        setError({ message: error.message, name: 'UNKNOWN_ERROR' });
        setStatus(RunStatus.FAILED);
      },
    });
  };

  const triggerRun = async ({ isPublic, input }: { isPublic: boolean; input: RunAppInput }) => {
    setStatus(RunStatus.IN_PROGRESS);
    await executeApiAction({
      action: async ({ publicClient, client }) => {
        const clientToUse = isPublic ? publicClient : client;
        const endpoint = isPublic ? `public/apps/${app.slug}/runs` : `apps/${app.id}/runs`;

        return clientToUse
          .put(endpoint, {
            json: {
              input,
              ...(purchaseId ? { purchaseId } : {}),
              ...(run ? { runId: run.id } : {}),
            },
          })
          .json<RunResult>();
      },
      onSuccess: (result: RunResult) => {
        if (result.waitForCompletion) {
          fetchResult({ runId: result.id });
        } else {
          setStatus(RunStatus.COMPLETED);
        }
      },
      onError: (err: any) => {
        setError({ message: err.message, name: err.name });
        setStatus(RunStatus.FAILED);
      },
    });
  };

  const resetApp = () => {
    setError(undefined);
    setStatus(RunStatus.CREATED);
    setRun(undefined);
    setCurrentSteps(app.appConfig?.steps || []);
    reset();

    if (onReset) {
      onReset();
    }
  };

  const renderStep = (step: StepConfig<StepTypeConfig>, register: UseFormRegister<RunAppInput>) => {
    const stepState = run?.state?.[step.id];

    return (
      <>
        {isStepType<DisplayOutputStepTypeConfig>(step, StepType.DISPLAY_OUTPUT) && (
          <Card my={2}>
            <CardBody>
              <Flex
                display="inline-block"
                whiteSpace="pre-line"
                wordBreak="break-word"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(`${stepState?.output || ''}`, { USE_PROFILES: { html: true } }),
                }}
              />
            </CardBody>
          </Card>
        )}
        {isStepType<TextInputStepTypeConfig>(step, StepType.TEXT_INPUT) && (
          <TextInput step={step} register={register} />
        )}
        {isStepType<SelectInputStepTypeConfig>(step, StepType.SELECT_INPUT) && (
          <SelectInput step={step} register={register} />
        )}
        {isStepType<GeolocationStepTypeConfig>(step, StepType.GEOLOCATION_INPUT) && (
          <GeolocationInput setValue={setValue} step={step} register={register} />
        )}
      </>
    );
  };

  const renderButton = () => {
    if (status === RunStatus.COMPLETED) {
      return (
        <Button key="reset-button" leftIcon={<MdRepeat />} {...getBrandButtonProps(app.brandColor)} onClick={resetApp}>
          Run again
        </Button>
      );
    }

    return (
      <Button
        key="continue-button"
        {...getBrandButtonProps(app.brandColor)}
        cursor="pointer"
        mb="2"
        {...(disabled ? {} : { type: 'submit' })}
      >
        Continue
      </Button>
    );
  };

  const renderMessage = () => {
    if (status === RunStatus.FAILED) {
      return error ? (
        <RunErrorAlert isPublic={isPublic} error={error} />
      ) : (
        <RunErrorAlert
          isPublic={isPublic}
          error={{
            name: 'UNKNOWN_ERROR',
            message: 'An unknown error occurred',
            details: 'We encountered an error when running the app, you can try submitting it again.',
          }}
        />
      );
    }

    if (status === RunStatus.COMPLETED && !run) {
      return (
        <Alert status="success">
          <AlertIcon />
          <Box>
            <AlertTitle>Success!</AlertTitle>
            <AlertDescription>Your response has been submitted.</AlertDescription>
          </Box>
        </Alert>
      );
    }

    return null;
  };

  const appIsConfigured = (app: App): boolean => {
    const { appConfig } = app;

    if (!appConfig) return false;
    if (_.isEmpty(appConfig)) return false;
    if (appConfig.steps.length === 0) return false;
    if (!currentSteps?.length) return false;

    return true;
  };

  const onSubmit = async (input: RunAppInput) => triggerRun({ isPublic, input });

  return (
    <AppContainer>
      <Flex mb={8} flexDirection="column" gap={4}>
        <Heading>{app.name}</Heading>
        {app.description && <Text fontSize="lg">{app.description}</Text>}
      </Flex>

      {appIsConfigured(app) && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Flex flexDirection="column" gap={4}>
              {currentSteps?.map((step) => (
                <React.Fragment key={step.id}>
                  <SlideFade in={true} transition={{ enter: { duration: 0.3 } }}>
                    {renderStep(step, register)}
                  </SlideFade>
                </React.Fragment>
              ))}
              {renderMessage()}
              <Box>
                {status === RunStatus.IN_PROGRESS ? (
                  <Flex alignItems="center" gap={2}>
                    <Spinner size="sm" />
                    <SpinnerTexts />
                  </Flex>
                ) : (
                  renderButton()
                )}
              </Box>
            </Flex>
          </form>
        </FormProvider>
      )}
    </AppContainer>
  );
};
