import { BoxProps, Button, CloseButton, Flex, Text } from '@chakra-ui/react';
import { useAppsContext } from '../../providers';
import { MdAccountBox, MdGroup, MdPayments, MdSettings, MdWallet } from 'react-icons/md';
import { NavItem, NavItemProps } from './NavItem';
import { Logo } from './Logo';
import { UsageCard } from '../UsageCard';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

interface SettingsSidebarProps extends BoxProps {
  onClose: () => void;
}

export const SettingsSidebar = ({ onClose, ...rest }: SettingsSidebarProps) => {
  const { selectedApp } = useAppsContext();

  const Categories: Array<{ name: string; items: NavItemProps[]; shouldDisplay?: () => boolean }> = [
    {
      name: 'Account',
      items: [
        {
          name: 'Profile',
          to: `/settings/profile`,
          icon: MdAccountBox,
        },
        {
          name: 'Billing',
          to: `/settings/billing`,
          icon: MdWallet,
        },
        {
          name: 'Payments',
          to: `/settings/payments`,
          icon: MdPayments,
        },
      ],
    },
    {
      name: 'App',
      shouldDisplay: () => !!selectedApp,
      items: [
        {
          name: 'Users',
          to: `/settings/app/users`,
          icon: MdGroup,
        },
        {
          name: 'Settings',
          to: `/settings/app/settings`,
          icon: MdSettings,
        },
      ],
    },
  ];

  return (
    <Flex
      transition="2s ease"
      w={{ base: 'full', md: 60 }}
      flexDirection="column"
      height="full"
      maxHeight="100vh"
      overflow="scroll"
      px={2}
      gap={4}
      {...rest}
    >
      <Flex alignItems="center" justifyContent={{ base: 'space-between' }} p={4}>
        <Logo />
        <CloseButton onClick={onClose} display={{ base: 'flex', md: 'none' }} />
      </Flex>

      {selectedApp && (
        <Button as={Link} to={`/apps/${selectedApp.id}/editor`} size="sm" leftIcon={<ArrowBackIcon />} variant="ghost">
          Back to app
        </Button>
      )}

      <Flex flexDirection="column" gap={4} flexGrow={1} py={4}>
        {Categories.filter(({ shouldDisplay }) => (shouldDisplay ? shouldDisplay() : true)).map(({ name, items }) => (
          <Flex flexDirection="column" key={name}>
            <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" px={4} mb={1}>
              {name}
            </Text>
            <Flex flexDir="column" gap={1}>
              {items.map((item) => (
                <NavItem to={item.to} key={item.to} icon={item.icon} name={item.name} />
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex py={4}>
        <UsageCard />
      </Flex>
    </Flex>
  );
};
