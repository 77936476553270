import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ReactElement } from 'react';

export const ConfirmButton = ({
  title,
  cta,
  icon,
  onClick,
  body,
  colorScheme,
  ...rest
}: {
  title?: string;
  cta?: string
  icon?: ReactElement<any>;
  onClick: () => void;
  body: string;
} & ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const iconOnly = !title && icon;
  return (
    <>
      <Button
        {...rest}
        w={'auto'}
        onClick={onOpen}
        {...(iconOnly ? { iconSpacing: 0 } : {})}
        leftIcon={icon}
        colorScheme={colorScheme}
      >
        {title}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title || 'Delete'}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onClick();
                  onClose();
                }}
                ml={3}
                colorScheme={colorScheme}
              >
                {cta || title || 'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
