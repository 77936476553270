import { Flex, Select, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { SoftCard } from '../../components/generic/SoftCard';
import { useAppsContext } from '../../providers';
import { useAppActions } from '../../hooks';
import { usePrivileges } from '../../hooks/roles';
import { App } from '../../types';

const formFields = ['schedule'] as const;
type ScheduleFormFields = Pick<App, (typeof formFields)[number]>;

export const SchedulePage: React.FC = () => {
  const { selectedApp } = useAppsContext();
  const { updateApp } = useAppActions();
  const { id } = useParams();
  const privileges = usePrivileges();

  const { register, handleSubmit, setValue } = useForm<ScheduleFormFields>(
    selectedApp ? { values: _.pick(selectedApp, formFields) } : undefined
  );

  const onUpdateApp = (data: ScheduleFormFields) => {
    if (!id) return;
    updateApp({ ...data, id });
  };

  if (!selectedApp) return null;

  return (
    <SoftCard title="Schedule">
      <Flex flexDirection="column" gap={4}>
        <Text>
          Enabling a schedule will automatically run your app on the given time interval. Scheduling can be used to
          automate recurring tasks.
        </Text>

        <form onSubmit={handleSubmit(onUpdateApp)}>
          <Select
            mb="4"
            disabled={!privileges.APPS.UPDATE}
            {...register('schedule')}
            onChange={(event) => {
              const value = event.target.value || null;
              setValue('schedule', value);
              handleSubmit(onUpdateApp)();
            }}
          >
            <option value="">No schedule</option>
            <option value="cron(0 * * * ? *)">Every hour</option>
            <option value="cron(0 0 * * ? *)">Every day</option>
          </Select>
        </form>
      </Flex>
    </SoftCard>
  );
};
