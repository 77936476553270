import * as React from 'react';
import { Card, Heading } from '@chakra-ui/react';

export const SoftCard = ({
  title,
  children,
  onClick,
  ...rest
}: {
  title?: string;
  children?: React.ReactNode;
  onClick?: (...args: any[]) => void;
  [x: string]: any;
}) => {
  const padding =
    'p' in rest
      ? rest.p
      : {
          base: 4,
          md: 8,
        };

  return (
    <Card onClick={onClick} p={padding} boxShadow="lg" borderRadius="lg" {...rest}>
      {title && (
        <Heading as="h2" fontSize="lg" mb={4}>
          {title}
        </Heading>
      )}
      {children}
    </Card>
  );
};
